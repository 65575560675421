import { getCookie } from "./Cookie";

export const fetchCompany = (pib) => {
  let request = new Request(`${process.env.REACT_APP_API_URL}/rest/jwt/companies/${pib}`, {
      method: 'GET',
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("jwt")}`,
      }),
  });
 
  return fetch(request)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });

}

export const fetchCompanyByUserId = (id) => {
  let request = new Request(`${process.env.REACT_APP_API_URL}/rest/jwt/companies/team/${id}`, {
      method: 'GET',
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("jwt")}`,
      }),
  });

  return fetch(request)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });

}