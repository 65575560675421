import React from "react";
import { useTranslation } from "react-i18next";
import Anchor from "../../components/Anchor/Anchor";

import Footer from "../../components/Footer/Footer";
import Toolbar from "../../components/Toolbar/Toolbar";
import styles from "./AccessDenied.module.css";

const AccessDenied = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles["toolbar"]}>
        <Toolbar />
      </div>
      <div className={styles["container"]}>
        {errorIcon}
        <br />
        <h2 className={styles["title"]}>{t("encountered-error")}!</h2>
        <div className={styles["text"]}>
          <p>
            {t("currenty-not-logged-in")}.{" "}
            <Anchor className="primary" to="/">
              {t("login")}
            </Anchor>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccessDenied;

const errorIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#EF4444" />
    <path
      d="M31 17L17 31"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 17L31 31"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
