import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getCookie } from "../../helpers/Cookie";
import { runnerActions } from "../../store/slices/runner-slice";

import UserInfoDetails from "../../components/UserInfo/Details/UserInfoDetails";
import EventInfoDetails from "../../components/EventInfo/Details/EventInfoDetails";
import PaymentInfoDetails from "../../components/PaymentInfo/Details/PaymentInfoDetails";
import PostLoginLayout from "../../components/Layout/PostLogin/PostLoginLayout";
import styles from "./EventDetails.module.css";
import fetchUserPaymentSlip from "../../helpers/fetchUserPaymentSlip";
import fetchPrice from "../../helpers/fetchPrice";
import { eventActions } from "../../store/slices/event-slice";
import { fetchCompany } from "../../store/actions/event-actions";
import CompanyDetails from "../../components/CompanyInfo/CompanyDetails/CompanyDetails";

const EventDetails = () => {
  const { t, i18n } = useTranslation();
  const [price, setPrice] = useState();
  const event = useSelector((state) => state.event);
  const user = useSelector((state) => state.user);
  const runner = useSelector((state) => state.runner);
  const company = useSelector((state) => state.company)
  const dispatch = useDispatch();

  useEffect(() => {
    const data = JSON.parse(getCookie("enrolledEvent"));
    if (data) { 
      dispatch(runnerActions.loadRunner(data));
      dispatch(fetchCompany(data.id, data.eventId));
    }
  }, [dispatch]);

  const adjustPrice = () => {
    if (runner.paymentStatus === "PAID" || runner.paymentStatus === "GRATIS" || runner.paymentStatus === "REFUND") {
      fetchUserPaymentSlip(runnerCategory.id, user.id).then((data) => {
        setPrice(data.paymentSlip.amount);
        dispatch(eventActions.updatePriceRsd());
        dispatch(eventActions.updatePriceEur());
      });
    } else {
      fetchPrice(runnerCategory.id).then((data) => {
        dispatch(eventActions.updatePriceRsd(data.priceRsd));
        dispatch(eventActions.updatePriceEur(data.priceEur));
      });
    }
  };

  const runnerEvent = event.valuesEvent.find((e) => e.id === runner.eventId);

  let runnerCategory;
  if (runnerEvent) {
    runnerCategory = runnerEvent.categories.category.find(
      (ctg) => ctg.mnemonic === runner.eventCategoryId
    );
    adjustPrice();
  }

  return (
    <PostLoginLayout>
      <div className={styles.top}>
        <h1 className={styles.title}>{t("event-details-title")}</h1>
        <div className={styles.description}>
          {t("event-details-description")}
        </div>
      </div>

      <div className={styles.data}>
        <div>
          <UserInfoDetails />
        </div>

        <div>
          <EventInfoDetails
            paymentStatus={runner.paymentStatus}
            eventName={
              runnerEvent &&
              (i18n.language === "sr" ? runnerEvent.nameSr : runnerEvent.name)
            }
            categoryName={runnerEvent && runnerCategory.name}
            predictedFinishTime={runner.predictedFinishTime}
            tShirtSize={runner.tShirtSize}
          />
        </div>

        <div>
          {company.id ? (
            <CompanyDetails
            />
          ) : (
            <PaymentInfoDetails
            eventName={
              runnerEvent &&
              (i18n.language === "sr" ? runnerEvent.nameSr : runnerEvent.name)
            }
            categoryName={runnerEvent && runnerCategory.mnemonic}
            categoryId={runnerEvent && runnerCategory.id}
            moneyDin={price || event.priceRsd}
            moneyEuro={event.priceEur}
          />
          )
          };
        </div>
      </div>
    </PostLoginLayout>
  );
};

export default EventDetails;
