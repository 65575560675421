import { createSlice } from "@reduxjs/toolkit";

const initialPaymentState = {
  selectedMethod: {
    value: "",
    name: "",
  },
  valuesMethod: {
    CC: "CC",
  },
  isMethodChangable: false,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialPaymentState,
  reducers: {
    selectPaymentMethod(state, action) {
      state.selectedMethod.value = action.payload.value;
      state.selectedMethod.name = action.payload.name;
    },
    loadEventData(state, action) {
      if (action.payload.length !== 0) {
        const data = action.payload[0];
        state.selectedMethod.value = data.paymentMethod;
        state.selectedMethod.name = state.valuesMethod[data.paymentMethod];
      } else {
        state.selectedMethod = {};
      }
    },
    reset(state) {
      state.selectedMethod = {};
    },
    toggle(state, action) {
      if (action.payload === "methodChange") {
        state.isMethodChangable = !state.isMethodChangable;
        return;
      }
    },
    resetCheckbox(state, action) {
      if (action.payload === "isMethodChangable") {
        state.isMethodChangable = false;
      }
    },
  },
});

export const paymentActions = paymentSlice.actions;
export default paymentSlice.reducer;
