import { createSlice } from "@reduxjs/toolkit";

const initialRunnerState = {
  id: "",
  paymentMethod: "",
  paymentStatus: "",
  eventId: "",
  eventCategoryId: "",
  paymentId: "",
  predictedFinishTime: "",
  tShirtSize: ""
};

const runnerSlice = createSlice({
  name: "runner",
  initialState: initialRunnerState,
  reducers: {
    loadRunner(state, action) {
      state.id = action.payload.id;
      state.paymentMethod = action.payload.paymentMethod;
      state.paymentStatus =  action.payload.paymentStatus;
      state.eventId = action.payload.eventId;
      state.eventCategoryId = action.payload.eventCategoryId;
      state.paymentId = action.payload.paymentId;
      state.predictedFinishTime= action.payload.predictedFinishTime;
      state.tShirtSize = action.payload.tShirtSize
    },
  },
});

export const runnerActions = runnerSlice.actions;
export default runnerSlice.reducer;
