import React from "react";
import { useTranslation } from "react-i18next";

import Anchor from "../Anchor/Anchor";

import styles from "./Error.module.css";

const Error = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["container"]}>
      {errorIcon}
      <br />
      <h3 className={styles["form-title"]}>{t("encountered-error")}!</h3>
      <p className={styles["form-text"]}>
        {t("check-internet") + " "}
        <Anchor className="primary">{t("try-again")}</Anchor>
      </p>
      <p className={styles["form-text"]}>
        {t("if-error-persists")}
        <b> support@bgdmarathon.org</b>
      </p>
    </div>
  );
};

export default Error;

const errorIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#EF4444" />
    <path
      d="M31 17L17 31"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 17L31 31"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
