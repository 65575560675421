import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { successActions } from "../../store/slices/success-slice";

import Anchor from "../Anchor/Anchor";
import styles from "./ForgotPasswordForm.module.css";

const RecoveryMailSent = () => {
  const username = useSelector((state) => state.recovery.username);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sendAgainHandler = () => {
    dispatch(successActions.setForgotPasswordDisplayed(true));
    dispatch(successActions.setIsPasswordRecovered(false));
  };

  return (
    <div className={styles["page-container"]}>
      <div className={styles["center-div"]}>
        <div className={styles.icon}> {successfulIcon}</div>
        <h1 className={styles["form-title"]}>{t("check-your-mail")}</h1>
        <p className={styles["form-text"]}>
          {t("we-sent-instructions")} {username}
        </p>
        <p className={styles["form-text"]}>
          {t("missing-mail")}
          {"? "}
          <Anchor className="primary" onClick={sendAgainHandler}>
            {t("send-again")}
          </Anchor>
        </p>
      </div>
    </div>
  );
};

export default RecoveryMailSent;

const successfulIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#10B981"></circle>
    <path
      d="M32.25 18L20.25 30L15 24.75"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
