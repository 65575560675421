import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { generateQR } from "../../../helpers/generateQR";

import { LabelText } from "../../LabelText/LabelText";
import PaymentSlip from "../../PaymentSlip/PaymentSlip";
import styles from "./PaymentInfoDetails.module.css";

const PaymentInfoDetails = (props) => {
  const user = useSelector((state) => state.user);
  const runner = useSelector((state) => state.runner);
  const payment = useSelector((state) => state.payment);
  const paymentMethodValue = useSelector((state) => state.runner.paymentMethod);

  const { t } = useTranslation();

  const [isModalShown, setIsModalShown] = useState(false);

  const paymentMethodVisibleValue = Object.entries(payment.valuesMethod).map(
    (method) => {
      if (method[0] === paymentMethodValue) {
        return method[1];
      }
    }
  );

  const showModalHandler = () => {
    setIsModalShown(true);
  };

  const closeModalHandler = () => {
    setIsModalShown(false);
  };

  const [qrSrc, setQrSrc] = useState();

  useEffect(() => {
    if (paymentMethodValue === "IPS" && props.moneyDin) {
      generateQR(user, parseInt(props.moneyDin), props.eventName, props.categoryId).then((response) => {
        setQrSrc(response);
      });
    }
  }, [paymentMethodValue, user, setQrSrc, props.moneyDin, props.categoryId, props.eventName]);

  return (
    <>
      {isModalShown && (
        <PaymentSlip
          name={user.name}
          lastname={user.lastname}
          event={props.eventName}
          category={props.categoryName}
          paymentId={runner.paymentId}
          onClose={closeModalHandler}
        />
      )}
      <div className={styles.main}>
        <div className={styles.title}>{t("payment-info")}</div>
        <div className={styles.info}>
          <LabelText label={t("payment-method")} placeholder={t("payment-method")}>
            {paymentMethodVisibleValue}
          </LabelText>

          {paymentMethodValue === "BT" && (
            <>
              <svg version="1.1" viewBox="0 0 650 323">
                {paymentSlip}
                <foreignObject
                  name="payer"
                  x="35"
                  y="56"
                  width="350"
                  height="30"
                >
                  <p>{user.name + " " + user.lastname}</p>
                </foreignObject>
                <foreignObject
                  name="purpose-of-payment"
                  x="35"
                  y="116"
                  width="350"
                  height="50"
                >
                <p>{`Prijava za trku`}</p>
                </foreignObject>
                <foreignObject
                  name="recipient"
                  x="35"
                  y="176"
                  width="350"
                  height="30"
                >
                  <p>Beogradski maraton d.o.o.</p>
                </foreignObject>
                <foreignObject
                  name="payment-code"
                  x="413"
                  y="59"
                  width="200"
                  height="30"
                >
                  <p>289</p>
                </foreignObject>
                <foreignObject
                  name="amount"
                  x="483"
                  y="59"
                  width="200"
                  height="30"
                >
                  <p>{t("check-price-short")}</p>
                </foreignObject>
                <foreignObject
                  name="recipient-account"
                  x="409"
                  y="96"
                  width="255"
                  height="30"
                >
                  <p>160-6000001609055-89</p>
                </foreignObject>
                <foreignObject
                  name="model"
                  x="366"
                  y="134"
                  width="255"
                  height="30"
                >
                  <p>00</p>
                </foreignObject>
                <foreignObject
                  name="reference-number"
                  x="409"
                  y="134"
                  width="255"
                  height="30"
                >
                  <p>{runner.paymentId}</p>
                </foreignObject>
              </svg>
              <div className={styles["payment-slip-button"]}>
                {zoomIn}
                <p onClick={showModalHandler}>{t("click-to-zoom")}</p>
              </div>
            </>
          )}

          <div className={styles["total-sum"]}>
            <div>{t('total')} </div>
            <div>
              {props.moneyDin && `${props.moneyDin} RSD`}
              {props.moneyEuro && `[${props.moneyEuro} EUR]`}  
            </div>
          </div>

          {paymentMethodValue === "IPS" && (
            <div style={{display: "flex", justifyContent: "center"}}>
              <img
                alt="QR-Code"
                src={qrSrc}
                style={{ display: "inline-block", width: "100%", maxWidth: "250px" }}
              />
            </div>
          )}

          {paymentMethodValue === "FP" && fpContent}
        </div>
      </div>
    </>
  );
};

export default PaymentInfoDetails;

const paymentSlip = (
  <image
    width="650"
    height="323"
    href= {process.env.REACT_APP_WP_URL + "/wp-content/themes/event-manager/assets/img/uplatnica.png"}
  ></image>
);

const zoomIn = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.8487 17.3729 13.551 16.3199 14.9056L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9056 16.3199C13.551 17.3729 11.8487 18 10 18C5.58172 18 2 14.4183 2 10ZM10 7C10.5523 7 11 7.44772 11 8V9H12C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11H11V12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12V11H8C7.44772 11 7 10.5523 7 10C7 9.44772 7.44772 9 8 9H9V8C9 7.44772 9.44772 7 10 7Z"
      fill="#9CA3AF"
    />
  </svg>
);

const fpContent = (
  <div className={styles["foreign-payment-container"]}>
    <div className={styles.intermediary}>
      <p>Intermediary:</p>
      <p>
        BCITITMM
        <br />
        INTESA SANPAOLO SPA
        <br />
        MILANO, ITALY
      </p>
    </div>
    <div className={styles["acc-institution"]}>
      <p>Account with institution:</p>
      <p>
        DBDBRSBG
        <br />
        BANCA INTESA AS, BEOGRAD
        <br />
        MILENTIJA POPOVIĆA 7B
        <br />
        BEOGRAD, REPUBLIKA SRBIJA
      </p>
    </div>
    <div className={styles.beneficiary}>
      <p>Beneficiary:</p>
      <p>
        /RS35160005390001265511
        <br />
        BEOGRADSKI MARATON
        <br />
        DR IVE POPOVICA DJANIJA 3A
        <br />
        Beograd (Savski Venac)
        <br />
        Republic of Serbia
      </p>
    </div>
  </div>
);
