import { userActions } from "../slices/user-slice";
import { authActions } from "../slices/auth-slice";
import { runnerActions } from "../slices/runner-slice";
import { eventActions } from "../slices/event-slice";
import { getCookie, setCookie } from "../../helpers/Cookie";
import fetchPrice from "../../helpers/fetchPrice";
import {errorsActions} from "../slices/errors-slice";

export const sendRunner = (navigate, runnerData, categoryId) => {
  return async (dispatch) => {
    const fetchRequest = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/rest/jwt/runners`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify(runnerData),
        }
      );

      // Handle minimum age error
      if(response.status === 406) {
        dispatch(errorsActions.setAgeLimitError(true));
        throw new Error("User's birthdate does not fulfill the minimal age limit");
      }

      if (!response.ok) {
        throw new Error("Couldn't send runner's request!");
      }
      const data = await response.json();
      return data;
    };

    try {
      const data = await fetchRequest();
      setCookie(
        "enrolledEvent",
        JSON.stringify(data.runner),
        24 * 60 * 60 * 1000
      );
      dispatch(runnerActions.loadRunner(data.runner));
      if (data.runner.paymentMethod === "CC") {
        initiateCreditCardPayment(dispatch, categoryId);
      } else {
        dispatch(authActions.setIsSubmittingRunner(false));
        navigate("/event-enroll-success");
      }
    } catch (error) {
      dispatch(authActions.setIsSubmittingRunner(false));
      console.log(error);
    }
  };
};

const initiateCreditCardPayment = (dispatch, categoryId) => {
  const bankPaymentForm = document.querySelector("#bankPaymentForm form");
  fetchPrice(categoryId)
    .then((response) => {
      dispatch(eventActions.updatePriceRsd(response.priceRsd));
      dispatch(eventActions.updatePriceEur(response.priceEur));
    })
    .then(() => {
      bankPaymentForm.submit();
    });
};

export const updateUser = (userData) => {
  return async (dispatch) => {
    const fetchRequest = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/rest/jwt/user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify(userData),
        }
      );
      if (!response.ok) {
        throw new Error("Couldn't update the user!");
      }
      const data = await response.json();
      return data;
    };

    try {
      const updatedData = await fetchRequest();
      dispatch(userActions.loadUser(updatedData.user));
      setCookie(
        "userLogged",
        JSON.stringify(updatedData.user),
        3 * 60 * 60 * 1000
      );
    } catch (error) {
      console.log(error);
      dispatch(authActions.setIsSubmittingRunner(false));
    }
  };
};
