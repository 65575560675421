import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import countries from '../../../helpers/countries.json'

import { LabelText } from "../../LabelText/LabelText";
import styles from "./UserInfoDetails.module.css";

const UserInfoDetails = (props) => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);

  const checkGender = () => {
    if (user.gender === "FEMALE") {
      return t("female");
    } else if (user.gender === "MALE") {
      return t("male");
    } else {
      return "";
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>{t("user-info")}</div>
      <div className={styles.info}>
        <LabelText label={t("firstname")} placeholder={t("firstname")}>
          {user.name}
        </LabelText>

        <LabelText label={t("lastname")} placeholder={t("lastname")}>
          {user.lastname}
        </LabelText>

        <LabelText label={t("gender")} placeholder={t("gender")}>
          {checkGender()}
        </LabelText>

        <LabelText label={t("date-of-birth")} placeholder={t("date-of-birth")}>
          {user.dateOfBirth}
        </LabelText>

        <LabelText label={t("country")} placeholder={t("country")}>
          {countries[`${user.initialCountry}`]}
        </LabelText>

        <LabelText label={t("city")} placeholder={t("city")}>
          {user.initialCity}
        </LabelText>

        <LabelText label={t("phone-number")} placeholder={t("phonenumber")}>
          {user.initialPhoneNum}
        </LabelText>

        <LabelText label="Email*" placeholder="Email">
          {user.email}
        </LabelText>
      </div>
    </div>
  );
};

export default UserInfoDetails;
