import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  name: "",
  lastname: "",
  id: "",
  dateOfBirth: "",
  country: "",
  city: "",
  phoneNum: "",
  email: "",
  language: "",
  gender: "",
  role: "",
  askedForConsent: "",
  consentAbbottWorldMarathonMajors: "",
  consentBgmarNewsletterAndPromotions: "",
  consentPersonalDataThirdParties: "",

  initialCountry: "",
  initialCity: "",
  initialPhoneNum: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    loadUser(state, action) {
      state.name = action.payload.firstName;
      state.lastname = action.payload.lastName;
      state.id = action.payload.id;
      state.country = action.payload.country;
      state.initialCountry = action.payload.country;
      state.dateOfBirth = action.payload.birthday !== null
                        ? action.payload.birthday.substr(0, 10)
                        : "";
      state.city = action.payload.city;
      state.initialCity = action.payload.city;
      state.phoneNum = action.payload.phoneNo;
      state.initialPhoneNum = action.payload.phoneNo;
      state.email = action.payload.username;
      state.language = action.payload.language;
      state.gender = action.payload.gender;
      state.role = action.payload.role;
      state.askedForConsent = action.payload.askedForConsent;
      state.consentAbbottWorldMarathonMajors =
        action.payload.consentAbbottWorldMarathonMajors;
      state.consentBgmarNewsletterAndPromotions =
        action.payload.consentBgmarNewsletterAndPromotions;
      state.consentPersonalDataThirdParties =
        action.payload.consentPersonalDataThirdParties;
    },
    updateField(state, action) {
      state[action.payload.name] = action.payload.value;
    },
    reset(state) {
      state.city = state.initialCity;
      state.country = state.initialCountry;
      state.phoneNum = state.initialPhoneNum;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
