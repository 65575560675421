import React from "react";

import Toolbar from "../../components/Toolbar/Toolbar";
import Footer from "../../components/Footer/Footer";
import Anchor from "../../components/Anchor/Anchor";
import styles from "./TermsOfUse.module.css";

const TermsOfUse = () => {
  return (
    <>
      <div className={styles["toolbar"]}>
        <Toolbar />
      </div>
      <div className={styles["page-container"]}>
        <div className={styles["content-container"]}>
          <h2 className={styles["subtitle"]}>dm Ženska trka 2022.</h2>
          <p className={styles["para"]}>Datum održavanja: 02. Oktobar 2022. </p>
          <p className={styles["para"]}>
            Lokacija: Ada Ciganlija, kod Okruglog kupatila
          </p>
          <p className={styles["para"]}>Vreme: 09h - 12h</p>
          <p className={styles["para"]}>Start trke: 10h </p>
          <p className={styles["para"]}>Dužina staze: 7700m </p>
          <br />
          <p className={styles["para"]}>
            Organizator: Beogradski maraton d.o.o.{" "}
          </p>
          <p className={styles["para"]}>Domaćin: JP "Ada Ciganlija" </p>
          <br />
          <h2 className={styles["subtitle"]}>Sadržaj učesničkog paketa:</h2>
          <p></p>
          <ul className={styles["list"]}>
            <li className={styles["list-item"]}>Startni broj sa čipom</li>
            <li className={styles["list-item"]}>Funkcionalna majica</li>
            <li className={styles["list-item"]}>Medalja na cilju</li>
            <li className={styles["list-item"]}>Okrepa na cilju</li>
            <li className={styles["list-item"]}>Pokloni sponzora i partnera</li>
            <li className={styles["list-item"]}>
              Zdravstveno obezbeđenje na stazi i cilju
            </li>
            <li className={styles["list-item"]}>Garderoba</li>
            <li className={styles["list-item"]}>Rezultati (online)</li>
            <li className={styles["list-item"]}>Diploma (online)</li>
          </ul>
          <p></p>
          <br />
          <h2 className={styles["subtitle"]}>Cena učesničkog paketa:</h2>
          <p>1990 RSD (20 EUR)</p>
          <br />
          <h2 className={styles["subtitle"]}>Preuzimanje učesničkih paketa</h2>
          <p className={styles["para"]}>
            Lokacija i radno vreme centra za preuzimanje učesničkog paketa:
            Uskoro ćemo objaviti detalje.
          </p>
          <p className={styles["para"]}>
            Preuzimanje učesničkog paketa biće omogućeno samo za učesnice koje
            su se ranije prijavile i svoj učesnički paket platile.
          </p>
          <p className={styles["para"]}>
            Prijava/uplata na dan trke nije moguća!
          </p>
          <br />
          <h2 className={styles["subtitle"]}>Važno:</h2>
          <p></p>
          <ul className={styles["list"]}>
            <li className={styles["list-item"]}>
              U slučaju vrlo nepovoljnih vremenskih prilika, organizator
              zadržava pravo pomeranja datuma trke i u tom slučaju nije dužan da
              izvrši povraćaj novca za startninu u slučaju da novi datum ne
              odgovara učesnici.
            </li>
            <li className={styles["list-item"]}>
              Prijava postaje važeća tek posle evidentirane uplate učesničkog
              paketa.
            </li>
            <li className={styles["list-item"]}>
              Prilikom registracije koristite važeću email adresu. Jedna
              trkačica - jedna registracija. Nije moguće prijaviti više trkačica
              sa jednog naloga.
            </li>
            <li className={styles["list-item"]}>
              Prijavite se što pre. Organizator zadržava pravo ograničenja broja
              učesnica.
            </li>
            <li className={styles["list-item"]}>
              U slučaju nepovoljne epidemijske situacije u periodu pred događaj,
              opredelićemo alternativni datum održavanja Ženske trke
            </li>
          </ul>
          <p></p>
          <p className={styles["para"]}>
            <b>Odustajanje od učešća ili nepredviđene okolnosti</b>
          </p>
          <ul className={styles["list"]}>
            <li className={styles["list-item"]}>
              Učesnik može odustati od trke i nije u obavezi da o tome obavesti
              organizatora.
            </li>
            <li className={styles["list-item"]}>
              U slučaju odustajanja učesnika ili nemogućnosti da učestvuje u
              trci usled nepredviđenih okolnosti, organizator nije u obavezi da
              vrati uplaćeni novac.
            </li>
            <li className={styles["list-item"]}>
              U slučaju otkazivanja ili odlaganja događaja usled nastanka više
              sile - spoljašnji događaji i vanredne okolnosti, uključujući
              COVID19 pandemiju ili bilo koju drugu pandemiju, prirodne nepogode
              (poplave, zemljotresi i slično), rat ili terorističke aktivnosti,
              javni neredi, požari ili eksplozije ili mere državnih organa
              kojima se otežava ili onemogućava održavanje događaja, a koje se
              nisu mogle unapred predvideti, izbeći niti otkloniti – organizator
              nije u obavezi da izvrši povraćaj uplaćenih sredstava za učesnički
              paket.
            </li>
            <li className={styles["list-item"]}>
              U slučaju da državni organ donese posebne mere koje su neophodne
              da se događaj održi (vakcinalni status ili negativan test kao
              uslov za učešće…) organizator nije u obavezi da izvrši povraćaj
              uplaćenih sredstava za učesnički paket u slučaju odustajanja
              učesnice.
            </li>
          </ul>
          <h2 className={styles["subtitle"]}>Osnovni podaci o firmi</h2>
          <p className={styles["para"]}>
            Pun naziv pravnog subjekta: Beogradski Maraton d.o.o.
          </p>
          <p className={styles["para"]}>
            Adresa: dr. Ive Popovića Đanija 3a, 11000 Beograd
          </p>
          <p className={styles["para"]}>
            Delatnost i šifra: Ostale sportske delatnosti – 9319
          </p>
          <p className={styles["para"]}>Matični broj: 06303773</p>
          <p className={styles["para"]}>Poreski broj: 100223037</p>
          <p className={styles["para"]}>
            Web adresa:{" "}
            <Anchor className="primary" href="https://www.bgdmarathon.org/">
              https://www.bgdmarathon.org/
            </Anchor>
          </p>
          <p className={styles["para"]}>
            Kontakt telefon: 011/ 3690-709; 011/3690-729
          </p>
          <p className={styles["para"]}>
            Kontakt e-mail:
            <Anchor className="primary" href="mailto: office@bgdmarathon.org">
              office@bgdmarathon.org
            </Anchor>
          </p>

          <h2 className={styles["subtitle"]}>Izjava o konverziji</h2>
          <p className={styles["para"]}>
            Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije –
            dinar (RSD).Za informativni prikaz cena u drugim valutama koristi se
            srednji kurs Narodne Banke Srbije. Iznos za koji će biti zadužena
            Vaša platna kartica biće izražen u Vašoj lokalnoj valuti kroz
            konverziju u istu po kursu koji koriste kartičarske organizacije, a
            koji nama u trenutku transakcije ne može biti poznat. Kao rezultat
            ove konverzije postoji mogućnost neznatne razlike od originalne cene
            navedene na našem sajtu.
          </p>

          <h2 className={styles["subtitle"]}>
            Kontak podaci – korisnički servis
          </h2>
          <p className={styles["para"]}>Beogradski maraton d.o.o.</p>
          <p className={styles["para"]}>
            11000 Beograd, Dr Ive Popovica Ðanija 3a
          </p>
          <p className={styles["para"]}>
            tel/fax: (+381 11) 369-0709; 306-5720
          </p>

          <h2 className={styles["subtitle"]}>
            Dostava robe i eventualna ograničenja
          </h2>
          <p className={styles["para"]}>
            Lokacija i radno vreme centra za preuzimanje učesničkog paketa:
            Uskoro ćemo objaviti detalje.
          </p>
          <p className={styles["para"]}>
            Preuzimanje učesničkog paketa biće omogućeno samo za učesnice koje
            su se ranije prijavile i svoj učesnički paket platile.
          </p>
          <p className={styles["para"]}>
            Prijava/uplata na dan trke nije moguća!
          </p>
          <p className={styles["para"]}>
            Učesničke pakete može preuzeti član porodice ili prijatelj umesto
            Vas.
          </p>
          <p className={styles["para"]}>
            Preuzimanje učesničkog paketa na dan trke neće biti moguće!
          </p>

          <h2 className={styles["subtitle"]}>Politika reklamacija</h2>
          <p className={styles["para"]}>Reklamacije i povraćaj sredstava</p>
          <p className={styles["para"]}>
            U slučaju povrede, zdravstvenog problema ili druge vrste sprečenosti
            da učestvujete na događaju, zahtev za refundaciju možete poslati na
            <Anchor className="primary" href="mailto: support@bgdmarathon.org">
              {" "}
              support@bgdmarathon.org.
            </Anchor>
          </p>

          <h2 className={styles["subtitle"]}>Zaštita privatnosti korisnika</h2>
          <p className={styles["para"]}>
            U ime Beogradskog Maratona d.o.o. obavezujemo se da ćemo čuvati
            privatnost svih naših kupaca. Prikupljamo samo neophodne, osnovne
            podatke o kupcima/ korisnicima i podatke neophodne za poslovanje i
            informisanje korisnika u skladu sa dobrim poslovnim običajima i u
            cilju pružanja kvalitetne usluge. Dajemo kupcima mogućnost izbora
            uključujući mogućnost odluke da li žele ili ne da se izbrišu sa
            mailing lista koje se koriste za marketinške kampanje. Svi podaci o
            korisnicima/kupcima se strogo čuvaju i dostupni su samo zaposlenima
            kojima su ti podaci nužni za obavljanje posla. Svi zaposleni [ime
            prodajnog mesta] (i poslovni partneri) odgovorni su za poštovanje
            načela zaštite privatnosti.
          </p>

          <h2 className={styles["subtitle"]}>
            Zaštita poverljivih podataka o transakciji
          </h2>
          <p className={styles["para"]}>
            Prilikom unošenja podataka o platnoj kartici, poverljive informacija
            se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi
            upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije
            kriptografske tehnologije. Sigurnost podataka prilikom kupovine,
            garantuje procesor platnih kartica, Banca Intesa ad Beograd, pa se
            tako kompletni proces naplate obavlja na stranicama banke. Niti
            jednog trenutka podaci o platnoj kartici nisu dostupni našem
            sistemu.
          </p>

          <h2 className={styles["subtitle"]}>Povraćaj sredstva</h2>
          <p className={styles["para"]}>
            U slučaju vraćanja robe i povraćaja sredstava kupcu koji je
            prethodno platio nekom od platnih kartica, delimično ili u celosti,
            a bez obzira na razlog vraćanja, Beogradski Maraton d.o.o. je u
            obavezi da povraćaj vrši isključivo preko VISA, EC/MC, Maestro, Amex
            i Dina metoda plaćanja, što znači da će banka na zahtev prodavca
            obaviti povraćaj sredstava na račun korisnika kartice
          </p>

          <h2 className={styles["subtitle"]}>Izjava o PDV-u</h2>
          <p className={styles["para"]}>
            PDV uračunat u cenu i nema skrivenih troškova
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
