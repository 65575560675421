import { useState } from "react";

import generateUID from "../../helpers/generateUID";
import styles from "./Checkbox.module.css";

const Checkbox = (props) => {
  const checkboxId = `checkbox-id-${generateUID(5)}`;

  const [checked, setChecked] = useState();

  const toggleHandler = (e) => {
    props.onChange(!checked, e);
    setChecked((prevState) => !prevState);
  };

  const checkmarkStyle = !checked && props.error
    ? `${styles.checkmark} ${styles.error}`
    : `${styles.checkmark}`;

  return (
    <div className={styles.checkbox}>
      <input
        onChange={toggleHandler}
        type="checkbox"
        id={checkboxId}
        name={props.name}
        checked={props.checked}
        disabled={props.disabled}
      />
      <label htmlFor={checkboxId} className={styles.container}>
        <div className={styles.text}>
          <div className={checkmarkStyle}></div>
          {props.children}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
