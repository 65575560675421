import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { authActions } from "./store/slices/auth-slice";
import { getCookie } from "./helpers/Cookie";
import { userActions } from "./store/slices/user-slice";
import { fetchEvents } from "./store/actions/event-actions";
import queryString from "query-string";

import EventEntry from "./pages/EventEntry/EventEntry";
import EventDetails from "./pages/EventDetails/EventDetails";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import ForgotPassword from "./pages/ForgotPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import EmailConfirmation from "./pages/EmailConfirmation/EmailConfirmation";
import NotFound from "./pages/NotFound/NotFound";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import EventEnrollSuccess from "./pages/EventEnrollSuccess/EventEnrollSuccess";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import PasswordReset from "./pages/PasswordReset";
import TeamSuccessful from "./pages/TeamSuccessful/TeamSuccessful";
import TeamEntry from "./pages/TeamEntry/TeamEntry";
import PaymentFailed from "./pages/PaymentFailed/PaymentFailed";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";

import "./App.css";
import { useTranslation } from "react-i18next";

function App() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const queryParams = queryString.parse(window.location.search);
  const query = "?" + queryString.extract(window.location.search);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (getCookie("userLogged")) {
      const data = JSON.parse(getCookie("userLogged"));
      dispatch(authActions.login());
      dispatch(userActions.loadUser(data));
      dispatch(fetchEvents());
    }
  }, [dispatch]);

  useEffect(() => {
    if (queryParams.lang) {
      i18n.changeLanguage(queryParams.lang);
      navigate(window.location.pathname);
    }
  }, []);

  const navigate = (path) => {
    if (queryParams.lang) {
      let q = query.replaceAll(`lang=${queryParams.lang}`, "");
      return <Navigate to={path + q} replace={true} />;
    } else {
      return <Navigate to={path + query} replace={true} />;
    }
  };

  return (
    <>
      <Routes>
        <Route path="/" element={isLoggedIn ? navigate("/event-entry") : navigate("/login")} />
        <Route path="/login" element={isLoggedIn ? navigate("/event-entry") : <Login />} />
        <Route path="/register" element={isLoggedIn ? navigate("/event-entry") : <Registration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/confirm-email" element={<EmailConfirmation />} />
        <Route path="/team-entry" element={isLoggedIn ? <TeamEntry /> : <AccessDenied />} />
        <Route path="/team-enroll-success" element={isLoggedIn ? <TeamSuccessful /> : <AccessDenied />} />
        <Route path="/event-entry" element={isLoggedIn ? queryParams.teamId ? navigate("/team-entry") : <EventEntry /> : <AccessDenied />} />
        <Route path="/event-details" element={isLoggedIn ? <EventDetails /> : <AccessDenied />} />
        <Route path="/event-enroll-success" element={isLoggedIn ? <EventEnrollSuccess /> : <AccessDenied />} />
        <Route path="/payment-failed" element={isLoggedIn ? <PaymentFailed /> : <AccessDenied />} />
        <Route path="/payment-success" element={isLoggedIn ? <PaymentSuccess /> : <AccessDenied />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
