import { useTranslation } from "react-i18next";
import Anchor from "../../components/Anchor/Anchor";
import styles from "./NotFound.module.css";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.page}>
      <div className={styles.logo}>{MarathonLogo}</div>
      <h1 className={styles.title}>404</h1>
      <p className={styles.paragraph}>{t("page-was-not-found")}</p>
      <Anchor className={styles.anchor} href="/">
        {ArrowLeft} {t("go-to-home-page")}
      </Anchor>
    </div>
  );
};
const MarathonLogo = (
  <svg
    width="118"
    height="40"
    viewBox="0 0 118 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.7907 1.90521C27.3224 1.90496 27.8602 1.86153 28.3874 1.77572C29.0025 1.67455 29.6 1.52067 30.1745 1.31393L23.4287 1.31348C24.4992 1.7034 25.6367 1.90472 26.7907 1.90521Z"
      fill="#D0D0D0"
    />
    <path
      d="M20.44 2.36308C22.3452 3.54232 24.5409 4.16565 26.7905 4.16565C27.4449 4.16565 28.1035 4.11172 28.7497 4.00697C30.5564 3.71242 32.2262 3.04034 33.7135 2.01045L34.3617 1.56136C34.4841 1.47631 34.6086 1.39437 34.733 1.31348H32.7189C31.4608 2.11623 30.0669 2.64622 28.5682 2.89099C27.9822 2.98641 27.3837 3.03515 26.7905 3.03515C24.7514 3.03515 22.7611 2.47094 21.0353 1.40163C20.9876 1.37259 20.9409 1.34355 20.8943 1.31348H18.9932C19.4485 1.69722 19.9308 2.04778 20.44 2.36308Z"
      fill="#D0D0D0"
    />
    <path
      d="M19.2507 4.28493C21.5127 5.68509 24.1211 6.42562 26.7918 6.42562C27.5666 6.42562 28.3486 6.36235 29.114 6.23789C31.2547 5.88837 33.2357 5.09183 35.0009 3.86903L35.6491 3.41994C36.595 2.76446 37.6156 2.24899 38.6838 1.88184V1.31348H37.0679C36.3523 1.64225 35.6626 2.03534 35.005 2.49065L34.3568 2.93974C32.7306 4.06609 30.9062 4.80039 28.9325 5.12191C28.2262 5.23704 27.5054 5.29512 26.7918 5.29512C24.3306 5.29512 21.9296 4.6137 19.846 3.32348C18.9271 2.75512 18.088 2.08097 17.3371 1.31348H15.8125C16.7957 2.48131 17.9511 3.48009 19.2507 4.28493Z"
      fill="#D0D0D0"
    />
    <path
      d="M13.9165 2.56843C15.0896 4.00593 16.4835 5.23081 18.061 6.20678C20.6809 7.82889 23.7 8.68661 26.7918 8.68661C27.6879 8.68661 28.5923 8.61298 29.478 8.46881C31.9526 8.06536 34.2437 7.14229 36.2879 5.72657L36.9362 5.27852C37.49 4.89374 38.075 4.56911 38.6838 4.30567V3.0839C37.8458 3.40542 37.044 3.82858 36.2921 4.34923L35.6439 4.79728C33.7397 6.11758 31.6042 6.97635 29.2965 7.35283C28.4699 7.48766 27.6277 7.55611 26.7918 7.55611C23.9106 7.55611 21.0968 6.75751 18.6553 5.24637C17.1856 4.33575 15.8851 3.19488 14.7929 1.85383C14.6477 1.67648 14.5077 1.49602 14.3708 1.31348H12.9883C13.2766 1.7439 13.5867 2.16291 13.9165 2.56843Z"
      fill="#D0D0D0"
    />
    <path
      d="M36.9306 6.65586C34.7474 8.16804 32.3008 9.15333 29.6592 9.58375C28.7133 9.73829 27.7477 9.81608 26.7904 9.81608C23.4891 9.81608 20.2646 8.90027 17.4653 7.16822C15.781 6.12484 14.2916 4.81802 13.0398 3.28303C12.5285 2.65556 12.0638 1.998 11.6459 1.31348H10.3359C10.8649 2.25521 11.4747 3.15235 12.1644 3.9966C13.4951 5.62908 15.0788 7.01991 16.87 8.12862C19.8477 9.97269 23.2786 10.9466 26.7904 10.9466C27.8089 10.9466 28.8346 10.8636 29.8407 10.6997C32.6503 10.2413 35.2515 9.19378 37.5737 7.58516L38.2219 7.13607C38.3733 7.03131 38.5268 6.93278 38.6834 6.84048V5.55233C38.3028 5.74317 37.9336 5.96097 37.5789 6.20678L36.9306 6.65586Z"
      fill="#D0D0D0"
    />
    <path
      d="M1.33789 26.1092C1.66252 25.993 1.983 25.8655 2.29726 25.7265C2.72664 25.5357 3.14876 25.322 3.56155 25.0866C2.80028 24.5504 2.05871 23.9893 1.33789 23.4043V24.847C1.35345 24.8584 1.36901 24.8719 1.3856 24.8843C1.37004 24.8905 1.35345 24.8957 1.33789 24.9019V26.1092Z"
      fill="#D0D0D0"
    />
    <path
      d="M2.7547 26.7593C2.29317 26.9636 1.82023 27.1451 1.33691 27.3038V28.4883C1.97788 28.2943 2.60432 28.062 3.21313 27.7923C4.60395 27.1752 5.91491 26.3579 7.10868 25.3623C7.26114 25.2347 7.41153 25.1051 7.55984 24.9713C7.28499 24.8115 7.01326 24.6477 6.74567 24.4817C4.81864 23.289 3.01192 21.9345 1.33691 20.4316V21.9334C2.68418 23.0816 4.11026 24.1395 5.60895 25.1009C4.71181 25.7595 3.75556 26.3154 2.7547 26.7593Z"
      fill="#D0D0D0"
    />
    <path
      d="M7.83255 26.231C6.55789 27.2941 5.15773 28.1674 3.67149 28.826C2.91644 29.161 2.1365 29.442 1.33789 29.6671V30.838C2.29518 30.586 3.22966 30.2583 4.12887 29.86C5.71157 29.1579 7.20092 28.2286 8.55648 27.0991C9.53867 26.2797 10.4348 25.365 11.2303 24.3693C10.1018 23.8238 8.99727 23.2181 7.93626 22.5605C5.52799 21.0701 3.31885 19.3111 1.33789 17.3125V18.8942C3.1695 20.6335 5.17744 22.1819 7.34094 23.5219C8.02027 23.942 8.71932 24.3434 9.4308 24.723C8.92882 25.2571 8.39469 25.7622 7.83255 26.231Z"
      fill="#D0D0D0"
    />
    <path
      d="M38.6831 37.7035C37.7768 37.9377 36.8595 38.148 35.935 38.3292C35.8624 38.4386 35.7901 38.5491 35.7165 38.6589L38.6835 38.659L38.6831 37.7035Z"
      fill="#D0D0D0"
    />
    <path
      d="M38.6835 35.3662C37.3311 35.7344 35.9548 36.0414 34.5577 36.2862C34.5059 36.3681 34.4551 36.45 34.4042 36.5299C33.9468 37.2538 33.4687 37.9643 32.9719 38.6592H34.3524C34.6583 38.2153 34.957 37.7652 35.2474 37.3098C36.408 37.0941 37.553 36.8359 38.6835 36.5361V35.3662Z"
      fill="#D0D0D0"
    />
    <path
      d="M33.8631 35.2569C35.5008 34.9893 37.1084 34.6335 38.6838 34.1927V33.0166C37.0451 33.4895 35.3711 33.866 33.6629 34.144C33.4991 34.171 33.3321 34.1969 33.1651 34.2228C32.9452 34.5952 32.7202 34.9633 32.4941 35.3222C31.766 36.4734 30.986 37.5863 30.1553 38.6587H31.5741C32.2317 37.773 32.8571 36.8613 33.4493 35.9258C33.5872 35.707 33.7262 35.483 33.8631 35.2569Z"
      fill="#D0D0D0"
    />
    <path
      d="M32.4619 33.1815C32.8042 33.1349 33.1454 33.083 33.4804 33.0291C35.2529 32.7397 36.9881 32.3425 38.6828 31.8395V30.6592C36.9321 31.1954 35.1357 31.6134 33.2989 31.9131C32.7907 31.9961 32.2742 32.0697 31.7546 32.134C31.3812 32.8082 30.9881 33.4719 30.5816 34.1139C29.5745 35.7091 28.4596 37.2264 27.2461 38.6577H28.7137C29.729 37.4038 30.6718 36.0887 31.5378 34.7186C31.8531 34.2187 32.1632 33.7032 32.4619 33.1815Z"
      fill="#D0D0D0"
    />
    <path
      d="M31.0423 31.0791C31.7393 31.0034 32.4342 30.909 33.1166 30.798C35.0177 30.4879 36.8753 30.0461 38.682 29.4756V28.2881C36.8193 28.8959 34.9016 29.3615 32.9341 29.682C32.0764 29.822 31.201 29.9341 30.3246 30.016C29.8143 31.0054 29.2594 31.9752 28.6703 32.9065C27.3687 34.9674 25.8773 36.8913 24.2168 38.6586H25.7487C27.1768 37.0552 28.4733 35.3356 29.6256 33.5112C30.1224 32.725 30.5974 31.9088 31.0423 31.0791Z"
      fill="#D0D0D0"
    />
    <path
      d="M29.6007 28.9433C30.6586 28.8624 31.7165 28.7369 32.7526 28.5678C34.7865 28.2359 36.7654 27.7454 38.6831 27.0982V25.9023C36.7104 26.5921 34.6714 27.1096 32.5711 27.4519C31.3525 27.651 30.1099 27.7879 28.8695 27.8595C28.2451 29.1818 27.5368 30.471 26.7599 31.7C25.143 34.2577 23.2191 36.5913 21.0234 38.6594H22.6435C24.5601 36.7365 26.259 34.6082 27.7152 32.3037C28.3976 31.225 29.0303 30.0966 29.6007 28.9433Z"
      fill="#D0D0D0"
    />
    <path
      d="M10.3746 18.7194C7.37618 16.8629 4.73144 14.5334 2.51504 11.7933C1.58886 10.6493 0.750839 9.44722 0.000976562 8.19434V10.2894C0.514368 11.0475 1.05784 11.787 1.63761 12.5027C3.9349 15.3414 6.67506 17.7559 9.78134 19.6788C11.8494 20.9586 14.0451 21.9989 16.3268 22.7799C16.0302 23.3534 15.7087 23.9145 15.3654 24.459C14.1187 26.4306 12.5785 28.1886 10.7863 29.6832C9.19327 31.0118 7.43841 32.1049 5.57257 32.9326C3.80526 33.7156 1.93112 34.2643 0.000976562 34.5619V35.6966C0.0196453 35.6955 0.038314 35.6945 0.0569828 35.6945C2.1261 35.3854 4.13507 34.8036 6.02995 33.9635C7.99121 33.0933 9.83527 31.9452 11.5082 30.5492C13.3917 28.9789 15.0096 27.1328 16.3185 25.0616C16.919 24.1105 17.449 23.1211 17.9043 22.0995C15.2648 21.3164 12.7435 20.1859 10.3746 18.7194Z"
      fill="#0C4076"
    />
    <path
      d="M8.72187 6.88201C10.3678 8.90032 12.3249 10.6189 14.5403 11.99C18.2253 14.2717 22.4704 15.478 26.815 15.478C28.0731 15.478 29.3415 15.3753 30.584 15.172C34.0554 14.6067 37.2716 13.3103 40.1445 11.32L40.7378 10.9103V9.6004C40.5345 9.69997 40.3374 9.81509 40.1487 9.94577L39.5025 10.3928C36.7686 12.2866 33.7069 13.5208 30.4025 14.0591C29.2191 14.252 28.0129 14.3495 26.815 14.3495C22.6799 14.3495 18.6412 13.2024 15.1336 11.0306C13.0261 9.72589 11.1623 8.0903 9.59619 6.16949C8.11099 4.34824 6.93486 2.32268 6.0958 0.142578H4.89062L4.90618 0.185102C5.79606 2.61516 7.07902 4.86786 8.72187 6.88201Z"
      fill="#0C4076"
    />
    <path
      d="M13.7744 40.0002C14.9889 39.2358 16.1578 38.3885 17.2717 37.4592C19.876 35.2874 22.1142 32.7329 23.9251 29.8682C24.9954 28.1746 25.9091 26.3782 26.6466 24.5196H26.8156C28.5601 24.5196 30.3181 24.3775 32.0408 24.0965C35.09 23.5997 38.0003 22.6932 40.7384 21.3926V20.1387C37.9557 21.5202 34.9832 22.4743 31.8593 22.9836C30.1967 23.2543 28.5 23.3922 26.8156 23.3922C26.5034 23.3922 26.1902 23.387 25.876 23.3777C25.1199 25.4334 24.1481 27.405 22.9719 29.2656C21.2243 32.0317 19.0629 34.4971 16.5499 36.5931C14.9869 37.8968 13.3119 39.0346 11.5508 40.0002H13.7744Z"
      fill="#0C4076"
    />
    <path
      d="M11.5621 16.8006C8.77945 15.0779 6.32554 12.9154 4.26783 10.3734C3.80941 9.80605 2.6312 8.30632 1.42292 6.14696C0.819291 5.06625 0.353609 4.08406 0.000976562 3.26367V5.87108C0.944787 7.71514 2.07736 9.45964 3.39143 11.0828C5.52901 13.7244 8.07833 15.9699 10.9689 17.76C13.6116 19.3956 16.4492 20.5987 19.4352 21.3486C18.8596 22.8514 18.1367 24.2961 17.2727 25.6631C15.9005 27.8339 14.2038 29.7692 12.2311 31.4141C10.4773 32.8776 8.54505 34.0817 6.48734 34.9934C4.47526 35.8864 2.33976 36.5004 0.140993 36.8208H0.111952C0.0746149 36.8208 0.0383141 36.8219 0.000976562 36.824V37.9555C0.037277 37.9513 0.0746149 37.9493 0.111952 37.9493C0.140993 37.9493 0.170033 37.9503 0.199073 37.9524C2.53786 37.6215 4.80715 36.9723 6.94472 36.0253C9.09786 35.0701 11.1193 33.81 12.954 32.2812C15.0169 30.5595 16.7904 28.536 18.2258 26.2657C19.3615 24.4683 20.2649 22.5444 20.9204 20.5323C17.6025 19.8468 14.4599 18.5949 11.5621 16.8006Z"
      fill="#0C4076"
    />
    <path
      d="M2.71783 0.767363L2.78835 0.960275C3.76432 3.62576 5.17277 6.0973 6.97431 8.30645C8.77895 10.5187 10.9248 12.4032 13.3528 13.9071C17.3956 16.4097 22.0514 17.7321 26.8151 17.7321C28.1945 17.7321 29.5853 17.6191 30.9481 17.3971C34.4765 16.8215 37.7653 15.5572 40.7378 13.6354V12.2798C37.7384 14.3468 34.3842 15.6941 30.7666 16.2842C29.464 16.4969 28.1343 16.6037 26.8151 16.6037C22.2609 16.6037 17.8105 15.3394 13.9471 12.9477C11.6259 11.5102 9.57341 9.70868 7.84863 7.59392C6.12695 5.48227 4.78073 3.11963 3.84729 0.572378L3.77676 0.379466C3.74668 0.297531 3.71038 0.218709 3.67201 0.141959C3.253 0.133662 2.83399 0.126401 2.41602 0.119141C2.47513 0.258119 2.5384 0.401247 2.60893 0.547486C2.64523 0.623198 2.68153 0.695799 2.71783 0.767363Z"
      fill="#0C4076"
    />
    <path
      d="M9.03343 40.0004C11.4624 38.8948 13.7473 37.4614 15.8268 35.7273C18.2496 33.7059 20.3332 31.3298 22.0186 28.6632C23.2984 26.6377 24.3273 24.4752 25.0896 22.2142C25.6663 22.2474 26.2429 22.264 26.8154 22.264C28.4386 22.264 30.0742 22.1323 31.6776 21.8709C34.8772 21.3492 37.9119 20.3442 40.7382 18.8746V17.5947C37.8715 19.1619 34.7735 20.2229 31.4961 20.757C29.9528 21.009 28.3784 21.1366 26.8154 21.1366C25.9774 21.1366 25.1301 21.0993 24.2858 21.0256C23.5339 23.5024 22.4521 25.865 21.0644 28.0607C19.4423 30.6276 17.4375 32.9156 15.1049 34.8613C12.3814 37.1316 9.29479 38.8647 5.9873 40.0004H9.03343Z"
      fill="#0C4076"
    />
    <path
      d="M1.50698 40C6.24159 39.1526 10.664 37.0949 14.3812 33.9938C16.6235 32.1238 18.5516 29.925 20.1104 27.4577C21.6101 25.0846 22.7375 22.5104 23.4604 19.8055C24.5743 19.9403 25.7017 20.0078 26.8156 20.0078C28.3174 20.0078 29.8306 19.8854 31.3138 19.6437C34.6721 19.0961 37.8323 17.9749 40.7373 16.302V14.9672C37.797 16.7594 34.5715 17.949 31.1291 18.5111C24.7579 19.5493 18.2332 18.255 12.7591 14.8655C10.2243 13.2963 7.98401 11.3299 6.0995 9.0191C4.21914 6.71247 2.74846 4.13203 1.72894 1.3483C1.58062 0.898177 1.43127 0.449088 1.28296 0H0.170093C0.286254 0.394119 0.402416 0.788238 0.51754 1.18132C0.852541 2.23196 1.35971 3.58545 2.13343 5.08932C2.43109 5.66805 3.41535 7.52871 5.14428 9.66421C7.12214 12.1078 9.48063 14.1862 12.1554 15.8415C15.2928 17.7841 18.7237 19.064 22.36 19.651C21.6641 22.2013 20.5937 24.6283 19.178 26.8685C17.6804 29.2384 15.829 31.3511 13.6749 33.1475C11.7603 34.7437 9.64968 36.0588 7.40113 37.0565C5.15777 38.0512 2.77646 38.7295 0.321518 39.0748L0.241657 39.0852L0.219877 39.0966L0.110975 39.0769L0 39.135V40H1.50698Z"
      fill="#0C4076"
    />
    <path
      d="M55.2306 16.5176V29.3099H52.6907V26.1662L52.8908 21.5063L52.7996 21.47L51.6483 24.8325L51.0094 26.5687H48.4508L47.8108 24.8325L46.6596 21.47L46.5683 21.5063L46.7509 26.1662V29.3099H44.2285V16.5176H46.8421L48.8521 21.3601C49.3033 22.4812 49.5833 23.2249 49.6933 23.5899H49.7669C49.8758 23.2249 50.1558 22.4812 50.607 21.3601L52.617 16.5176H55.2306Z"
      fill="#0C4076"
    />
    <path
      d="M63.8223 24.7225L63.5298 23.2601C63.3587 22.3225 63.1886 21.1526 63.0185 19.7514H62.9449C62.7375 21.3964 62.5674 22.5663 62.4336 23.2601L62.1411 24.7225H63.8223ZM64.3336 27.1899H61.6287L61.2087 29.3099H58.5225L61.5924 16.5176H64.3699L67.4409 29.3099H64.7547L64.3336 27.1899Z"
      fill="#0C4076"
    />
    <path
      d="M75.1551 22.2562C75.4227 21.9398 75.5575 21.4773 75.5575 20.8674C75.5575 20.2462 75.4227 19.7774 75.1551 19.46C74.8875 19.1437 74.4913 18.985 73.9675 18.985H72.8889V22.7312H73.9675C74.4913 22.7312 74.8875 22.5735 75.1551 22.2562ZM77.1101 17.6699C77.8538 18.4363 78.2251 19.5035 78.2251 20.8674C78.2251 22.2323 77.8538 23.2954 77.1101 24.0567C76.3675 24.8179 75.3314 25.1986 74.0038 25.1986H72.8889V29.3099H70.2939V16.5176H74.0038C75.3314 16.5176 76.3675 16.9013 77.1101 17.6699Z"
      fill="#0C4076"
    />
    <path
      d="M84.3292 24.7225L84.0367 23.2601C83.8655 22.3225 83.6955 21.1526 83.5254 19.7514H83.4517C83.2443 21.3964 83.0742 22.5663 82.9404 23.2601L82.6479 24.7225H84.3292ZM84.8405 27.1899H82.1356L81.7155 29.3099H79.0293L82.0993 16.5176H84.8778L87.9478 29.3099H85.2616L84.8405 27.1899Z"
      fill="#0C4076"
    />
    <path
      d="M96.8663 18.985H94.3627V29.3099H91.6951V18.985H89.1914V16.5176H96.8663V18.985Z"
      fill="#0C4076"
    />
    <path
      d="M104.16 26.5418C104.404 26.2306 104.526 25.7712 104.526 25.1624V20.6663C104.526 20.0575 104.404 19.597 104.16 19.2869C103.916 18.9757 103.55 18.8202 103.064 18.8202C102.576 18.8202 102.21 18.9757 101.967 19.2869C101.723 19.597 101.602 20.0575 101.602 20.6663V25.1624C101.602 25.7712 101.723 26.2306 101.967 26.5418C102.21 26.8529 102.576 27.0075 103.064 27.0075C103.55 27.0075 103.916 26.8529 104.16 26.5418ZM100.012 28.3692C99.2804 27.5955 98.9153 26.5262 98.9153 25.1624V20.6663C98.9153 19.3024 99.2804 18.2331 100.012 17.4594C100.743 16.6857 101.76 16.2988 103.064 16.2988C104.368 16.2988 105.385 16.6857 106.115 17.4594C106.846 18.2331 107.213 19.3024 107.213 20.6663V25.1624C107.213 26.5262 106.846 27.5955 106.115 28.3692C105.385 29.143 104.368 29.5298 103.064 29.5298C101.76 29.5298 100.743 29.143 100.012 28.3692Z"
      fill="#0C4076"
    />
    <path
      d="M117.119 16.5176V29.3099H114.506V24.1013H111.874V29.3099H109.261V16.5176H111.874V21.6349H114.506V16.5176H117.119Z"
      fill="#0C4076"
    />
    <path
      d="M46.3478 37.881C46.515 37.7181 46.5996 37.4673 46.5996 37.1265C46.5996 36.7922 46.5002 36.534 46.3023 36.3541C46.1055 36.1742 45.8367 36.0843 45.496 36.0843H44.6928V38.1244H45.5785C45.9235 38.1244 46.1806 38.0429 46.3478 37.881ZM44.6928 33.7425V35.6483H45.4737C46.079 35.6483 46.3816 35.3234 46.3816 34.6737C46.3816 34.3531 46.3065 34.1182 46.1563 33.9679C46.006 33.8177 45.7732 33.7425 45.4589 33.7425H44.6928ZM46.8314 36.2673C46.9922 36.4906 47.0716 36.7763 47.0716 37.1265C47.0716 37.5921 46.9404 37.9477 46.6779 38.1921C46.4155 38.4376 46.0483 38.5604 45.5785 38.5604H44.2283V33.3076H45.4589C45.8991 33.3076 46.242 33.4261 46.4864 33.6642C46.7319 33.9012 46.8546 34.2377 46.8546 34.6737C46.8546 34.9933 46.7933 35.2525 46.6705 35.4493C46.5478 35.6472 46.3816 35.7763 46.1711 35.8366C46.4515 35.9012 46.6716 36.0451 46.8314 36.2673Z"
      fill="#0C4076"
    />
    <path
      d="M51.2441 38.1328V38.5603H48.7151V33.3086H51.1616V33.7361H49.1807V35.6789H51.0335V36.1064H49.1807V38.1328H51.2441Z"
      fill="#0C4076"
    />
    <path
      d="M55.3705 38.117V38.5593H52.9844V33.3076H53.45V38.117H55.3705Z"
      fill="#0C4076"
    />
    <path
      d="M57.1264 38.2185C56.8608 37.9402 56.7285 37.5497 56.7285 37.0439V34.8154C56.7285 34.3054 56.8555 33.9139 57.1073 33.6409C57.3602 33.3689 57.7063 33.2324 58.1465 33.2324C58.5771 33.2324 58.921 33.3636 59.1782 33.6261C59.4364 33.8885 59.575 34.2578 59.5951 34.7329H59.1454C59.1253 34.3784 59.029 34.1118 58.8565 33.934C58.6829 33.7562 58.447 33.6673 58.1465 33.6673C57.8364 33.6673 57.5983 33.7636 57.4301 33.9562C57.2629 34.1488 57.1793 34.4377 57.1793 34.8228V37.0439C57.1793 37.4249 57.2692 37.7116 57.4491 37.9074C57.629 38.1021 57.8745 38.1995 58.1846 38.1995C58.4946 38.1995 58.7411 38.1074 58.9232 37.9222C59.1062 37.737 59.1972 37.4693 59.1972 37.1191V36.1815H58.1771V35.7614H59.6406V37.1191C59.6406 37.59 59.5062 37.9593 59.2385 38.2291C58.9708 38.5 58.6195 38.6344 58.1846 38.6344C57.7444 38.6344 57.392 38.4958 57.1264 38.2185Z"
      fill="#0C4076"
    />
    <path
      d="M61.8533 36.0768H62.5813C62.921 36.0768 63.1792 35.9741 63.3538 35.7688C63.5294 35.5646 63.6162 35.2662 63.6162 34.8757C63.6162 34.5011 63.5305 34.2186 63.358 34.0281C63.1855 33.8387 62.9284 33.7435 62.5887 33.7435H61.8533V36.0768ZM62.431 36.5117H61.8533V38.5603H61.3877V33.3086H62.5887C63.0691 33.3086 63.4384 33.4419 63.6987 33.7096C63.959 33.9774 64.0892 34.3562 64.0892 34.8461C64.0892 35.3011 63.9876 35.6704 63.7855 35.953C63.5823 36.2355 63.2987 36.4122 62.9337 36.482L64.2246 38.5603H63.6839L62.431 36.5117Z"
      fill="#0C4076"
    />
    <path
      d="M67.7429 36.9096L67.2021 34.733C67.127 34.4441 67.0677 34.169 67.0222 33.9087H67.0074C66.9619 34.169 66.9027 34.4441 66.8275 34.733L66.2868 36.9096H67.7429ZM67.8476 37.3297H66.1821L65.8741 38.5604H65.3789L66.7598 33.3076H67.2699L68.6508 38.5604H68.1555L67.8476 37.3297Z"
      fill="#0C4076"
    />
    <path
      d="M71.3523 38.117C71.7026 38.117 71.9724 38.0228 72.1629 37.8355C72.3523 37.6482 72.4475 37.372 72.4475 37.007V34.8684C72.4475 34.4981 72.3544 34.2187 72.166 34.0314C71.9787 33.8441 71.7078 33.7499 71.3523 33.7499H70.6391V38.117H71.3523ZM70.1746 33.3076H71.3523C71.8475 33.3076 72.2316 33.4409 72.5036 33.7087C72.7766 33.9764 72.9131 34.3552 72.9131 34.8451V37.0292C72.9131 37.5096 72.7755 37.8842 72.5004 38.154C72.2253 38.4249 71.8422 38.5593 71.3523 38.5593H70.1746V33.3076Z"
      fill="#0C4076"
    />
    <path
      d="M77.3173 38.1328V38.5603H74.7883V33.3086H77.2348V33.7361H75.2539V35.6789H77.1068V36.1064H75.2539V38.1328H77.3173Z"
      fill="#0C4076"
    />
    <path
      d="M85.3763 33.3076V38.5593H84.9117V35.7837C84.9117 35.2589 84.9266 34.7637 84.9562 34.2981L84.9266 34.2907C84.8811 34.4261 84.7763 34.6959 84.6112 35.1012L84.3858 35.6705L83.7404 37.3223H83.2758L82.6303 35.6705L82.4049 35.1012C82.2399 34.6959 82.1351 34.4261 82.0896 34.2907L82.06 34.2981C82.0896 34.7637 82.1044 35.2589 82.1044 35.7837V38.5593H81.6399V33.3076H82.1351L83.0727 35.5732C83.1933 35.8737 83.333 36.2641 83.4927 36.7445H83.5234C83.6832 36.2641 83.8229 35.8737 83.9435 35.5732L84.8811 33.3076H85.3763Z"
      fill="#0C4076"
    />
    <path
      d="M89.2634 36.9096L88.7226 34.733C88.6475 34.4441 88.5882 34.169 88.5427 33.9087H88.5279C88.4824 34.169 88.4232 34.4441 88.348 34.733L87.8073 36.9096H89.2634ZM89.3681 37.3297H87.7026L87.3946 38.5604H86.8994L88.2803 33.3076H88.7904L90.1713 38.5604H89.676L89.3681 37.3297Z"
      fill="#0C4076"
    />
    <path
      d="M92.1592 36.0768H92.8872C93.2269 36.0768 93.4851 35.9741 93.6597 35.7688C93.8343 35.5646 93.9221 35.2662 93.9221 34.8757C93.9221 34.5011 93.8364 34.2186 93.6639 34.0281C93.4914 33.8387 93.2343 33.7435 92.8946 33.7435H92.1592V36.0768ZM92.737 36.5117H92.1592V38.5603H91.6936V33.3086H92.8946C93.375 33.3086 93.7443 33.4419 94.0046 33.7096C94.2649 33.9774 94.3951 34.3562 94.3951 34.8461C94.3951 35.3011 94.2935 35.6704 94.0914 35.953C93.8882 36.2355 93.6047 36.4122 93.2396 36.482L94.5305 38.5603H93.9898L92.737 36.5117Z"
      fill="#0C4076"
    />
    <path
      d="M98.0485 36.9096L97.5088 34.733C97.4337 34.4441 97.3734 34.169 97.3279 33.9087H97.3131C97.2686 34.169 97.2083 34.4441 97.1332 34.733L96.5925 36.9096H98.0485ZM98.1533 37.3297H96.4877L96.1798 38.5604H95.6846L97.0655 33.3076H97.5755L98.9564 38.5604H98.4612L98.1533 37.3297Z"
      fill="#0C4076"
    />
    <path
      d="M102.873 33.6456H101.694V38.5608H101.219V33.6456H100.04V33.2012H102.873V33.6456Z"
      fill="#0C4076"
    />
    <path
      d="M107.336 33.2012V38.5608H106.861V36.0572H104.956V38.5608H104.48V33.2012H104.956V35.6127H106.861V33.2012H107.336Z"
      fill="#0C4076"
    />
    <path
      d="M111.579 37.8644C111.758 37.64 111.847 37.3184 111.847 36.8993V34.8624C111.847 34.4444 111.758 34.1227 111.579 33.8984C111.4 33.674 111.138 33.5608 110.791 33.5608C110.444 33.5608 110.18 33.674 110.001 33.8984C109.823 34.1227 109.734 34.4444 109.734 34.8624V36.8993C109.734 37.3184 109.823 37.64 110.001 37.8644C110.18 38.0887 110.444 38.2009 110.791 38.2009C111.138 38.2009 111.4 38.0887 111.579 37.8644ZM109.662 38.1861C109.387 37.8697 109.251 37.4401 109.251 36.8993V34.8624C109.251 34.3216 109.387 33.8931 109.662 33.5767C109.933 33.2603 110.31 33.1016 110.791 33.1016C111.27 33.1016 111.647 33.2603 111.92 33.5767C112.193 33.8931 112.329 34.3216 112.329 34.8624V36.8993C112.329 37.4401 112.193 37.8697 111.92 38.1861C111.647 38.5025 111.27 38.6601 110.791 38.6601C110.31 38.6601 109.933 38.5025 109.662 38.1861Z"
      fill="#0C4076"
    />
    <path
      d="M114.243 33.2012H114.733L116.181 36.3249C116.389 36.7587 116.584 37.2 116.762 37.6497L116.785 37.6423C116.744 36.9481 116.724 36.4318 116.724 36.0953V33.2012H117.199V38.5608H116.709L115.246 35.4138C115.169 35.2456 115.076 35.0307 114.967 34.7704C114.857 34.5101 114.769 34.2911 114.702 34.1123L114.679 34.1197C114.705 34.7937 114.718 35.2731 114.718 35.5598V38.5608H114.243V33.2012Z"
      fill="#0C4076"
    />
    <path
      d="M46.9276 9.24922C47.7038 9.24922 48.0919 8.87884 48.0919 8.1392C48.0919 7.37184 47.7038 6.98816 46.9276 6.98816H46.1879V9.24922H46.9276ZM44.2285 1.50684H49.559V3.32877H46.1879V5.16512H47.0917C48.0786 5.16512 48.826 5.43458 49.3328 5.97351C49.8396 6.51355 50.0935 7.23433 50.0935 8.1392C50.0935 9.15274 49.8484 9.90014 49.3605 10.3792C48.8715 10.8593 48.1152 11.0989 47.0917 11.0989H44.2285V1.50684Z"
      fill="#0C4076"
    />
    <path
      d="M56.8763 9.24922V11.0989H51.792V1.50684H56.7388V3.35649H53.7381V5.34365H56.4649V7.12455H53.7381V9.24922H56.8763Z"
      fill="#0C4076"
    />
    <path
      d="M62.3571 9.02306C62.5401 8.79018 62.631 8.44531 62.631 7.98845V4.61737C62.631 4.1605 62.5401 3.81563 62.3571 3.58276C62.1741 3.34989 61.9002 3.23346 61.5354 3.23346C61.1695 3.23346 60.8956 3.34989 60.7126 3.58276C60.5296 3.81563 60.4387 4.1605 60.4387 4.61737V7.98845C60.4387 8.44531 60.5296 8.79018 60.7126 9.02306C60.8956 9.25593 61.1695 9.37236 61.5354 9.37236C61.9002 9.37236 62.1741 9.25593 62.3571 9.02306ZM59.2466 10.3937C58.6988 9.81371 58.4238 9.01197 58.4238 7.98845V4.61737C58.4238 3.59385 58.6988 2.79322 59.2466 2.21216C59.7944 1.6322 60.5574 1.34277 61.5354 1.34277C62.5124 1.34277 63.2753 1.6322 63.8231 2.21216C64.372 2.79322 64.6459 3.59385 64.6459 4.61737V7.98845C64.6459 9.01197 64.372 9.81371 63.8231 10.3937C63.2753 10.9736 62.5124 11.263 61.5354 11.263C60.5574 11.263 59.7944 10.9736 59.2466 10.3937Z"
      fill="#0C4076"
    />
    <path
      d="M71.4015 3.35649H68.5106V11.0989H66.5645V1.50684H71.4015V3.35649Z"
      fill="#0C4076"
    />
    <path
      d="M76.5952 5.81134C76.7959 5.57404 76.8968 5.22695 76.8968 4.77008C76.8968 4.30434 76.7959 3.95282 76.5952 3.7144C76.3945 3.4771 76.0973 3.35844 75.7047 3.35844H74.8963V6.1673H75.7047C76.0973 6.1673 76.3945 6.04865 76.5952 5.81134ZM78.0611 2.37152C78.6189 2.94704 78.8973 3.74656 78.8973 4.77008C78.8973 5.7936 78.6189 6.5898 78.0611 7.16088C77.5045 7.73197 76.7271 8.01807 75.7324 8.01807H74.8963V11.1008H72.9502V1.50879H75.7324C76.7271 1.50879 77.5045 1.796 78.0611 2.37152Z"
      fill="#0C4076"
    />
    <path
      d="M83.1315 7.66015L82.912 6.56345C82.7845 5.8604 82.6569 4.98326 82.5283 3.93312H82.474C82.3187 5.16622 82.1912 6.04337 82.0903 6.56345L81.8707 7.66015H83.1315ZM83.5152 9.50981H81.487L81.1721 11.1H79.1572L81.4593 1.50684H83.5429L85.845 11.1H83.8302L83.5152 9.50981Z"
      fill="#0C4076"
    />
    <path
      d="M91.7918 3.34318H90.3258L90.216 6.06998C90.1705 7.37627 89.9233 8.44526 89.4753 9.27694H91.7918V3.34318ZM94.6006 9.27694V12.7301H92.6689V11.1H88.5172V12.7301H86.5977V9.27694H87.2153C87.8995 8.5373 88.2788 7.27647 88.3519 5.49446L88.5028 1.50684H93.7102V9.27694H94.6006Z"
      fill="#0C4076"
    />
    <path
      d="M96.5814 10.4628C96.0646 9.9283 95.8074 9.16316 95.8074 8.16736V4.44033C95.8074 3.43566 96.068 2.66275 96.588 2.12382C97.1092 1.58489 97.84 1.31543 98.7803 1.31543C99.7395 1.31543 100.48 1.58268 101 2.11717C101.522 2.65166 101.8 3.42568 101.836 4.44033H99.8904C99.8637 4.0012 99.7628 3.68627 99.5887 3.49443C99.4157 3.30259 99.1418 3.20722 98.767 3.20722C98.1461 3.20722 97.8356 3.61752 97.8356 4.44033V8.16736C97.8356 8.97132 98.1461 9.37385 98.767 9.37385C99.1418 9.37385 99.4202 9.27737 99.6032 9.08553C99.7861 8.89369 99.8904 8.58763 99.9181 8.16736H101.864C101.845 9.17203 101.569 9.9405 101.035 10.4694C100.5 10.9995 99.744 11.2645 98.767 11.2645C97.8256 11.2645 97.097 10.9973 96.5814 10.4628Z"
      fill="#0C4076"
    />
    <path
      d="M105.934 7.46831L105.4 8.33215V11.1H103.481V1.50684H105.4V5.17953L105.893 4.11055L107.236 1.50684H109.456L107.017 5.70072L109.868 11.1H107.674L105.934 7.46831Z"
      fill="#0C4076"
    />
    <path
      d="M111.087 1.50684H112.978V3.72687C112.978 4.19261 112.955 4.7892 112.91 5.51553C112.863 6.24186 112.805 6.89279 112.731 7.46831L112.772 7.50934L113.019 6.83735C113.202 6.33501 113.352 5.94689 113.471 5.67299L115.294 1.50684H117.199V11.0989H115.307V9.15385C115.307 8.5872 115.328 7.91077 115.37 7.12566C115.411 6.33945 115.463 5.65858 115.527 5.08306L115.486 5.04203L115.239 5.72733C114.928 6.56788 114.719 7.12122 114.609 7.38514L112.992 11.0989H111.087V1.50684Z"
      fill="#0C4076"
    />
  </svg>
);
const ArrowLeft = (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70711 0.792893C8.09763 1.18342 8.09763 1.81658 7.70711 2.20711L3.41421 6.5H15C15.5523 6.5 16 6.94771 16 7.5C16 8.05228 15.5523 8.5 15 8.5H3.41421L7.70711 12.7929C8.09763 13.1834 8.09763 13.8166 7.70711 14.2071C7.31658 14.5976 6.68342 14.5976 6.29289 14.2071L0.292893 8.20711C0.105357 8.01957 0 7.76522 0 7.5C0 7.23478 0.105357 6.98043 0.292893 6.79289L6.29289 0.792893C6.68342 0.402369 7.31658 0.402369 7.70711 0.792893Z"
      fill="#0C4076"
    />
  </svg>
);
export default NotFound;
