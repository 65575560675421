import { createSlice } from "@reduxjs/toolkit";

const initialTeamState = {
  id:null,
  eventId: null,
  categoryId: null,
  teamName: "",
  captain: {},
  members: [],
};

const teamSlice = createSlice({
  name: "team",
  initialState: initialTeamState,
  reducers: {
    setTeamName(state, action) {
      state.teamName = action.payload;
    },
    setTeamId(state, action) {
      state.id = action.payload;
    },
    setCategoryId(state, action) {
      state.categoryId = action.payload;
    },
    setEventId(state, action) {
      state.eventId = action.payload;
    },
    setCaptain(state, action) {
      state.captain = action.payload;
    },
    setMembers(state, action) {
      state.members = action.payload;
    },
  },
});

export const teamActions = teamSlice.actions;
export default teamSlice.reducer;
