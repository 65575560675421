import { t } from "i18next";
import { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { teamActions } from "../../store/slices/team-slice";
import Input from "../Input/Input";
import styles from "./TeamForm.module.css";
import {getCookie} from "../../helpers/Cookie";
const TeamForm = (props) => {
  const dispatch = useDispatch();
  const [member1, setMember1] = useState({ mail: "", teamPosition: 1 });
  const [member2, setMember2] = useState({ mail: "", teamPosition: 2 });
  const [member3, setMember3] = useState({ mail: "", teamPosition: 3 });
  const [member4, setMember4] = useState({ mail: "", teamPosition: 4 });
  const [teamNameError, setTeamNameError] = useState("");
  const event = useSelector((state) => state.event);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    dispatch(teamActions.setMembers([member1, member2, member3, member4]));
  }, [member1, member2, member3, member4]);

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  // Email validation regex

  const emailRegex = (i) => {
    if (i === "") {
      return true;
    } else {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      return i.match(validRegex) ? true : false;
    }
  };

  let timeoutId;
  const handleTeamName = async (e) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      const categoryId = event.selectedCategory.id;
      fetch(`${process.env.REACT_APP_API_URL}/rest/jwt/teams?eventCategoryId=${categoryId}&teamName=${e.target.value}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        })
      })
      .then(response => response.json())
      .then(data => {
        data.team ? setTeamNameError(t("team-name-error")) : setTeamNameError("");
      });

      dispatch(teamActions.setTeamName(e.target.value));
    }, 400);
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>{t("team-title")}</div>
      <div className={styles.info}>
        <Input
          placeholder={t("team-name")}
          onChange={handleTeamName}
          errorMessage={(errors["teamName"] && t("cant-be-empty")) || teamNameError}
        />
        <Input
          placeholder={t("email-position") + "1"}
          onChange={(e) =>
            setMember1({ mail: e.target.value, teamPosition: 1 })
          }
          value={member1.mail}
          errorMessage={
            (props.errorMessage === member1.mail && t("team-form-err")) ||
            (errors["memberMail1"] && t("cant-be-empty")) ||
            (errors["captainMail"] && t("your-email-error")) ||
            (errors["duplicateMail1"] && t("duplicate-mail")) ||
            (!emailRegex(member1.mail) && t("not-email"))
            
          }
        />
        <Input
          placeholder={t("email-position") + "2"}
          onChange={(e) =>
            setMember2({ mail: e.target.value, teamPosition: 2 })
          }
          value={member2.mail}
          errorMessage={
            (props.errorMessage === member2.mail && t("team-form-err")) ||
            (errors["memberMail2"] && t("cant-be-empty")) ||
            (errors["captainMail"] && t("your-email-error")) ||
            (errors["duplicateMail2"] && t("duplicate-mail")) ||
            (!emailRegex(member2.mail) && t("not-email")) 
            
          }
        />
        <Input
          placeholder={t("email-position") + "3"}
          onChange={(e) =>
            setMember3({ mail: e.target.value, teamPosition: 3 })
          }
          value={member3.mail}
          errorMessage={
            (props.errorMessage === member3.mail && t("team-form-err")) ||
            (errors["memberMail3"] && t("cant-be-empty")) ||
            (errors["captainMail"] && t("your-email-error")) ||
            (errors["duplicateMail3"] && t("duplicate-mail")) ||
            (!emailRegex(member3.mail) && t("not-email")) 
            
          }
        />
        <Input
          placeholder={t("email-position") + "4"}
          onChange={(e) =>
            setMember4({ mail: e.target.value, teamPosition: 4 })
          }
          value={member4.mail}
          errorMessage={
            (props.errorMessage === member4.mail && t("team-form-err")) ||
            (errors["memberMail4"] && t("cant-be-empty")) ||
            (errors["captainMail"] && t("your-email-error")) ||
            (errors["duplicateMail4"] && t("duplicate-mail")) ||
            (!emailRegex(member4.mail) && t("not-email"))
            
          }
        />
        <p>{t("team-form-disclamer")}</p>

        {props.errorMessage && <p className={styles['error-text']}>{t("user-under-age-limit-error")}</p>}
      </div>
    </div>
  );
};

export default TeamForm;
