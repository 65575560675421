import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  isLoggedIn: false,
  isSubmmited: false,
  isSubmittingRunner: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    submit(state, action) {
      state.isSubmmited = action.payload;
    },
    setIsSubmittingRunner(state, action) {
      state.isSubmittingRunner = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
