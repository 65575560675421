import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

import styles from "./PasswordReset.module.css";

const PasswordResetSuccessful = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className={styles["container"]}>
      {successfulIcon}
      <br />
      <h2 className={styles["title"]}>{t("password-successfully-changed")}!</h2>
      <p className={styles["text"]}>{t("new-password-successful")}</p>
      <Button onClick={() => navigate("/", { replace: true })} primary>
        {t('login')}
      </Button>
    </div>
  );
};

export default PasswordResetSuccessful;

const successfulIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#10B981"></circle>
    <path
      d="M32.25 18L20.25 30L15 24.75"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
