import { useTranslation } from 'react-i18next';

import { LabelText } from '../../LabelText/LabelText';
import styles from "../EventInfo.module.css";

const EventInfoDetails = (props) => {
    const { t } = useTranslation();

    return (
        <div className={styles.main}>
          <div className={styles.title}>{t('event-info')}</div>
          <div className={styles.info}>
            <LabelText label={t('event')} placeholder={t('event')}>
              {props.eventName}
            </LabelText>
    
            <LabelText label={t('category')} placeholder={t('category')}>
              {props.categoryName}
            </LabelText>

            <LabelText label={t('payment-status')} placeholder={t('payment-status')}>
              {props.paymentStatus}
            </LabelText>

            <LabelText label={t('predicted-finish-time')} placeholder={t('predicted-finish-time')}>
              {props.predictedFinishTime}
            </LabelText>
    
            <LabelText label={t('shirt-size')} placeholder={t('shirt-size')}>
              {props.tShirtSize}
            </LabelText>
          </div>
        </div>
      );
}

export default EventInfoDetails