import { createSlice } from "@reduxjs/toolkit";

const initialSuccessfulState = {
  isRegistrated: false,
  isPasswordRecovered: false,
  isPasswordReset: false,
};

const successSlice = createSlice({
  name: "success",
  initialState: initialSuccessfulState,
  reducers: {
    setIsRegistrated(state, action) {
      state.isRegistrated = action.payload;
    },
    setIsPasswordRecovered(state, action) {
      state.isPasswordRecovered = action.payload;
    },
    setIsReset(state, action) {
      state.isPasswordReset = action.payload;
    },
  },
});

export const successActions = successSlice.actions;
export default successSlice.reducer;
