import { useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../../Footer/Footer";
import Toolbar from "../../Toolbar/Toolbar";

import styles from "./PostLoginLayout.module.css";

const PostLoginLayout = (props) => {
  const user = useSelector((state) => state.user);
  const [hideBurger, setHideBurger] = useState(window.innerWidth > 768);

  window.onresize = () => {
    setHideBurger(window.innerWidth > 768);
  }
  
  return (
    <div>
      <div className={styles.toolbar}>
        <Toolbar
        firstLetter={user.name[0]}
        hideBurger={hideBurger}
        />
      </div>

      <main className={styles.main}>
        <div className={styles["page-container"]}>{props.children}</div>
      </main>

      <Footer />
    </div>
  );
};

export default PostLoginLayout;
