/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { successActions } from "../../store/slices/success-slice";
import { useTranslation } from "react-i18next";
import { registerDataActions } from "../../store/slices/register-data-slice";

import Input from "../Input/Input";
import Button from "../Button/Button";
import Checkbox from "../Checkbox/Checkbox";
import Select from "../Select/Select";
import Anchor from "../Anchor/Anchor";
import countries from "../../helpers/countries.json";
import styles from "./RegisterForm.module.css";
import InputDatePickerSimple from "../InputDatePickerSimple/InputDatePickerSimple";
import queryString from "query-string";
import phoneRegex from "../../helpers/phoneRegex";
import Recaptcha from "react-recaptcha";

const initialState = {
  password: "",
  confirmPassword: "",

  birthday: "",
  firstName: "",
  lastName: "",
  country: "",
  gender: "",
  language: "sr",
  username: "",
  city: "",
  phoneNo: "",

  checkbox: {
    termsOfUse: false,
    privacyPolicy: false,
    consentAbbottWorldMarathonMajors: false,
    consentBgmarNewsletterAndPromotions: false,
    consentPersonalDataThirdParties: false,
  },
};

const RegisterForm = () => {
  const dispatch = useDispatch();

  const [container, setContainer] = useState(initialState);
  const [registerError, setRegisterError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptchaVerified,setCaptchaVerified] = useState(false);
  const [triedSubmit, setTriedSubmit] = useState(false);

  const { t, i18n } = useTranslation();

  const validEmail = new RegExp(
    /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/
  );

  const validPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/);
  const queryParams = queryString.parse(window.location.search);

  const verifyCaptcha = (e) => {
    if (e) {
      setCaptchaVerified(true);
    }
  };

  useEffect(() => {
    if (queryParams.mail) {
      setContainer((prevState) => {
        return {
          ...prevState,
          ["username"]: queryParams.mail,
        };
      });
    }
  }, []);

  const isFormValid =
    container.birthday &&
    container.gender &&
    container.username &&
    validEmail.test(container.username) &&
    container.country &&
    container.city &&
    container.lastName &&
    container.firstName &&
    container.password &&
    validPassword.test(container.password) &&
    phoneRegex(container.phoneNo) &&
    container.checkbox.termsOfUse &&
    container.checkbox.privacyPolicy &&
    isCaptchaVerified;

  const fieldError = (fieldName, translatedName) => {
    let error;
    if (triedSubmit) {
      if (!container[fieldName]) {
        error = `${t("field")} ${
          translatedName.charAt(0).toUpperCase() + translatedName.slice(1)
        } ${t("cant-be-empty")}!`;
      }
      if (
        fieldName === "username" &&
        container.username.length !== 0 &&
        !validEmail.test(container.username)
      ) {
        error = t("wrong-format") + " Email " + t("of-field") + "!";
      }
      if (fieldName === "password" && !validPassword.test(container.password)) {
        error = t("password-policy") + ".";
      }
    }
    return error;
  };

  const checkboxError = (checkboxName) => {
    return triedSubmit && !container.checkbox[checkboxName];
  };

  const data = {
    password: container.password,
    confirmPassword: container.password,
    user: {
      birthday: container.birthday,
      firstName: container.firstName,
      lastName: container.lastName,
      country: container.country,
      gender: container.gender,
      language: i18n.language,
      username: container.username,
      city: container.city,
      phoneNo: container.phoneNo,
      consentAbbottWorldMarathonMajors:
        container.checkbox.consentAbbottWorldMarathonMajors,
      consentBgmarNewsletterAndPromotions:
        container.checkbox.consentBgmarNewsletterAndPromotions,
      consentPersonalDataThirdParties:
        container.checkbox.consentPersonalDataThirdParties,
    },
    teamId: queryParams.teamId ? queryParams.teamId : 0,
    position: queryParams.position ? queryParams.position : 0,
  };

  const updateField = (e) => {
    setContainer((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const selectDateHandler = (date) => {
    setContainer((prevState) => {
      return {
        ...prevState,
        birthday: date,
      };
    });
  };

  const toggleCheckboxHandler = (checked, e) => {
    setContainer((prevState) => {
      return {
        ...prevState,
        checkbox: {
          ...prevState.checkbox,
          [e.target.name]: checked,
        },
      };
    });
  };

  const register = (e) => {
    e.preventDefault();
    setTriedSubmit(true);
    if (!isFormValid) {
      setRegisterError(t("fill-all-required-fields") + "!");
      return;
    } else {
      setRegisterError("");
    }

    setIsLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/rest/jwt/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 409) {
          setRegisterError(t("user-already-registered") + ".");
          throw new Error("User already registered");
        }
        if (response.status === 500) {
          setRegisterError(
            t("encountered-error") +
              " " +
              t("during-registration") +
              ", " +
              t("try-again") +
              "."
          );
          throw new Error("Error 500");
        }

        if (response.ok) {
          dispatch(registerDataActions.saveData(data.user));
          dispatch(successActions.setIsRegistrated(true));
        }

        setIsLoading(false);
        return response.json();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className={styles["container"]}>
        <form onSubmit={register} className={styles["register-form"]}>
          <div className={styles["page-container"]}>
            <h3 className={styles["form-title"]}>{t("welcome")}</h3>
            <p
              className={`${styles["form-text"]} ${styles["form-text-header"]}`}
            >
              {t("create-new-account-to-see-events")}
            </p>

            <div className={styles["input-container"]}>
              <div className={styles["input-fields"]}>
                <div className={styles["input-field"]}>
                  <Input
                    type="text"
                    placeholder={t("firstname") + "*"}
                    name="firstName"
                    value={container.firstName}
                    errorMessage={fieldError("firstName", t("firstname"))}
                    onChange={updateField}
                  />
                </div>
                <div className={styles["input-field"]}>
                  <Input
                    type="text"
                    placeholder={t("lastname") + "*"}
                    name="lastName"
                    value={container.lastName}
                    errorMessage={fieldError("lastName", t("lastname"))}
                    onChange={updateField}
                  />
                </div>
              </div>

              <div className={styles["input-fields"]}>
                <div className={styles["input-field"]}>
                  <Select
                    name="gender"
                    onChange={updateField}
                    placeholder={t("gender") + "*"}
                    values={{ MALE: "MALE", FEMALE: "FEMALE" }}
                    errorMessage={fieldError("gender", t("gender"))}
                  />
                </div>
                <div className={styles["input-field"]}>
                  <InputDatePickerSimple
                    name="birthday"
                    onSelect={selectDateHandler}
                    errorMessage={fieldError("birthday", t("date-of-birth"))}
                    label={t("date-of-birth") + "*"}
                  />
                </div>
              </div>

              <div className={styles["input-fields"]}>
                <div className={styles["input-field"]}>
                  <Select
                    name="country"
                    onChange={updateField}
                    placeholder={t("country") + "*"}
                    values={countries}
                    errorMessage={fieldError("country", t("country"))}
                  />
                </div>
                <div className={styles["input-field"]}>
                  <Input
                    type="text"
                    placeholder={t("city") + "*"}
                    name="city"
                    value={container.city}
                    onChange={updateField}
                    errorMessage={fieldError("city", t("city"))}
                  />
                </div>
              </div>
            </div>

            <div className={styles["input-container"]}>
              <Input
                type="number"
                placeholder={t("phone-number")}
                name="phoneNo"
                value={container.phoneNo}
                onChange={updateField}
                errorMessage={
                  triedSubmit &&
                  !phoneRegex(container.phoneNo) &&
                  t("phone-invalid")
                }
              />
            </div>
            <div className={styles["input-container"]}>
              <Input
                type="text"
                placeholder="Email*"
                disabled={queryParams.mail}
                name="username"
                value={container.username}
                onChange={updateField}
                errorMessage={fieldError("username", "Email")}
              />
            </div>
            <div className={styles["input-container"]}>
              <Input
                type="password"
                placeholder={t("password") + "*"}
                name="password"
                value={container.password}
                onChange={updateField}
                changebleForPassword="true"
                errorMessage={fieldError("password", t("password"))}
              />
            </div>

            <br />

            <div className={styles["checkbox-container"]}>
              <Checkbox
                name="termsOfUse"
                error={checkboxError("termsOfUse")}
                onChange={toggleCheckboxHandler}
              >
                {t("registration-terms-of-use")}
                <a href={`${process.env.REACT_APP_WP_URL}/privacy-policy?lang=${i18n.language}`}
                   className="primary-small"
                   style={{color: 'rgba(12, 64, 118, 1)', fontSize: '16px'}}
                   target="_blank"
                >
                  {" "}
                  {t("in-this-privacy-policy")} ({t("required")})
                </a>
              </Checkbox>

              <Checkbox
                  name="privacyPolicy"
                  error={checkboxError("privacyPolicy")}
                  onChange={toggleCheckboxHandler}
              >
                {t("i-have-read-and")}{" "}
                <a href={`${process.env.REACT_APP_WP_URL}/privacy-policy?lang=${i18n.language}`}
                  className="primary-small"
                  style={{  color: 'rgba(12, 64, 118, 1)', fontSize: '16px' }}
                  target="_blank"
                >
                  {t("agree-fully-with-privacy-policy")} ({t("required")})
                </a>
              </Checkbox>

              <Checkbox
                name="consentAbbottWorldMarathonMajors"
                onChange={toggleCheckboxHandler}
              >
                {t("i-give-permission-for")}{" "}
                <b>{t("abbott-world-marathon-majors-data-collection")},</b>{" "}
                {t("according-to-privacy-policy")} ({t("optional")})
              </Checkbox>

              <Checkbox
                name="consentBgmarNewsletterAndPromotions"
                onChange={toggleCheckboxHandler}
              >
                {t("i-give-data-permission-for")}{" "}
                <b>{t("newsletter-and-promotions")}</b>,{" "}
                {t("according-to-privacy-policy")} ({t("optional")})
              </Checkbox>

              <Checkbox
                name="consentPersonalDataThirdParties"
                onChange={toggleCheckboxHandler}
              >
                {t("i-give-data-permission-for")}{" "}
                <b>{t("third-parties-marketing")},</b>{" "}
                {t("according-to-privacy-policy")} ({t("optional")})
              </Checkbox>
            </div>
            <br />
            <div className={styles.captcha}>
            <Recaptcha
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              render="explicit"
              verifyCallback={verifyCaptcha}
            />
            </div>
           
            <br />

            <div className={styles["button"]}>
              <Button loading={isLoading} primary stretch>
                {t("register-btn")}
              </Button>
              <div
                className={`${styles["input-container-error"]} ${styles.error}`}
              >
                {registerError}
              </div>{" "}
              <p className={`${styles["form-text"]} ${styles.black}`}>
                {t("already-registered")}?{" "}
                <Anchor className="primary" to={"/"}>
                  {t("login")}
                </Anchor>
              </p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegisterForm;
