import { createSlice } from "@reduxjs/toolkit";
import fetchPrice from "../../helpers/fetchPrice";

const initialEventState = {
  selectedEvent: {},
  valuesEvent: [],
  selectedCategory: {},
  valuesCategory: [],
  predictedFinishTime: "",
  tShirtSize: "",
  termsOfUse: false,
  privacyPolicy: false,
  priceRsd: "",
  priceEur: "",
  alreadyHasData: false,
};

const eventSlice = createSlice({
  name: "event",
  initialState: initialEventState,
  reducers: {
    toggle(state, action) {
      if (action.payload === "termsOfUse") {
        state.termsOfUse = !state.termsOfUse;
        return;
      }
      if (action.payload === "privacyPolicy") {
        state.privacyPolicy = !state.privacyPolicy;
        return;
      }
    },
    loadEvents(state, action) {
      state.valuesEvent = action.payload.filter(
        (event) => event.categories.category.length !== 0
      );
    },
    loadEventData(state, action) {
      if (action.payload.length !== 0) {
        state.alreadyHasData = true;
        const data = action.payload[0];
        state.selectedCategory = state.selectedEvent.categories.category.find(
          (ctg) => ctg.mnemonic === data.eventCategoryId
        );
        state.predictedFinishTime = data.predictedFinishTime;
        state.tShirtSize = data.tShirtSize;
      } else {
        state.selectedCategory = {};
        state.category = "";
        state.predictedFinishTime = "";
        state.tShirtSize = "";
        state.alreadyHasData = false;
      }
    },
    selectEvent(state, action) {
      state.selectedEvent = action.payload;
      state.valuesCategory = action.payload.categories.category;
    },
    selectCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    updatePriceForSelectedCategory(state) {
      fetchPrice(state.selectedCategory.id).then((data) => {
        state.priceRsd = data.priceRsd;
        state.priceEur = data.priceEur;
      });
    },
    updatePriceRsd(state, action) {
      state.priceRsd = action.payload;
    },
    updatePriceEur(state, action) {
      state.priceEur = action.payload;
    },
    selectPredictedFinishTime(state, action) {
      state.predictedFinishTime = action.payload;
    },
    selectTShirt(state, action) {
      state.tShirtSize = action.payload;
    },
    reset(state) {
      Object.keys(state).forEach((key) => {
        if (key !== "valuesEvent" && key !== "valuesCategory")
          state[key] = initialEventState[key];
      });
    },
  },
});

export const eventActions = eventSlice.actions;
export default eventSlice.reducer;
