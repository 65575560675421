import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import PostLoginLayout from "../../components/Layout/PostLogin/PostLoginLayout";
import UserInfo from "../../components/UserInfo/UserInfo";
import EventInfo from "../../components/EventInfo/EventInfo";
import styles from "./TeamEntry.module.css";
import Button from "../../components/Button/Button";
import { getCookie } from "../../helpers/Cookie";
import { updateUser } from "../../store/actions/event-entry-actions";
import { teamActions } from "../../store/slices/team-slice";
import { eventActions } from "../../store/slices/event-slice";
import { runnerActions } from "../../store/slices/runner-slice";
import phoneRegex from "../../helpers/phoneRegex";

const TeamEntry = () => {
  const { t } = useTranslation();
  const queryParams = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const team = useSelector((state) => state.team);
  const event = useSelector((state) => state.event);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [isSubmittingTeam, setIsSubmittingTeam] = useState(false);

  let request = new Request(
    `${process.env.REACT_APP_API_URL}/rest/jwt/teams/${queryParams.teamId}`,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("jwt")}`,
      }),
    }
  );

  useEffect(() => {
    fetch(request)
      .then((response) => response.json())
      .then((data) => {
        dispatch(teamActions.setTeamId(data.team.id));
        dispatch(teamActions.setEventId(data.team.eventId));
        dispatch(teamActions.setCategoryId(data.team.eventCategoryId));
        dispatch(teamActions.setTeamName(data.team.name));
      });
  }, []);

  useEffect(() => {
    if (Object.keys(event.valuesEvent).length !== 0 && team.categoryId) {
      let preselectedEvent = event.valuesEvent.find(
        (e) => e.id === team.eventId
      );
      let preselectedCategory = preselectedEvent.categories.category.find(
        (e) => e.id === team.categoryId
        );
      dispatch(eventActions.selectEvent(preselectedEvent));
      dispatch(eventActions.selectCategory(preselectedCategory));
    }
  }, [event.valuesEvent, team.categoryId, dispatch]);

  const submitHandler = () => {
    const requiredFieldsHaveError = {
      event: Object.keys(event.selectedEvent).length === 0,
      phone: !phoneRegex(user.phoneNum),
      category: Object.keys(event.selectedCategory).length === 0,
      predictedFinishTime: event.predictedFinishTime.length === 0,
      tShirtSize: event.tShirtSize.length === 0,
      termsOfUse: !event.termsOfUse,
      privacyPolicy: !event.privacyPolicy,
    };
    setErrors(requiredFieldsHaveError);
    let error = false;
    Object.values(requiredFieldsHaveError).forEach((fieldError) => {
      if (fieldError) {
        error = true;
        return;
      }
    });
    if (error) return;
    setIsSubmittingTeam(true);
    const requestAddMemberData = {
      runner: {
        eventId: event.selectedEvent.id,
        userId: user.id,
        tShirtSize: event.tShirtSize,
        paymentId: event.selectedCategory.id + "-" + user.id,
        paymentMethod: "BT",
        predictedFinishTime: event.predictedFinishTime,
        paymentStatus: "WAITING_VALIDATION",
        eventCategoryId: event.selectedCategory.mnemonic,
      },
      teamId: team.id,
      teamPosition: queryParams.position,
      hash: queryParams.hashTeam,
    };

    const userData = {
      user: {
        id: user.id,
        username: user.email,
        country: user.country,
        city: user.city,
        phoneNo: user.phoneNum,
      },
    };

    let requestAddMember = new Request(
      `${process.env.REACT_APP_API_URL}/rest/jwt/relayTeam/addMember`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        }),
        body: JSON.stringify(requestAddMemberData),
      }
    );

    dispatch(updateUser(userData));

    fetch(requestAddMember)
      .then((response) => {
        if (response.ok) { 
        return response.json();
       }else{
        navigate("/error", { replace: true })
        return Promise.reject(response);
       }
       })
      .then((data) => {
        setIsSubmittingTeam(false);
        dispatch(runnerActions.loadRunner(data));
        navigate("/team-enroll-success", { replace: true });
      });
  };
  return (
    <PostLoginLayout>
      <div className={styles.data}>
        <UserInfo errors={errors}/>
        <EventInfo
          errors={errors}
          preselectEventId={team.eventId}
          preselectCategoryId={team.categoryId}
        />
        <div>
          <div className={styles.main}>
            <div className={styles.title}>{t("confirm-team")}</div>
            <div className={styles.description}>
              {t("team-name")}: {team.teamName}
            </div>
            <div className={styles.description}>
              {t("position")}: {queryParams.position}
            </div>
            <Button
              onClick={submitHandler}
              primary
              stretch
              loading={isSubmittingTeam}
            >
              {t("enroll-for-team")}
            </Button>
          </div>
        </div>
      </div>
    </PostLoginLayout>
  );
};

export default TeamEntry;
