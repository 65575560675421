import Modal from "../Modal/Modal";
import styles from "./Dialog.module.css";

const Dialog = (props) => {
  const { title, text, children, buttons, maxWidth } = props;

  return (
    <Modal onBackdrop={props.onBackdrop}>
      <div className={styles.dialog} style={{maxWidth: maxWidth}}>
        {title && <div className={styles.title}>{title}</div>}
        {text && <div className={styles.text}>{text}</div>}
        {children}
        {buttons && <div className={styles.buttons}>{buttons}</div>}
      </div>
    </Modal>
  );
};

export default Dialog;
