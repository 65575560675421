import { getCookie } from "./Cookie";
let prices;
const fetchPrice = (categoryId) => {
      let request = new Request(`${process.env.REACT_APP_API_URL}/rest/jwt/events/price?eventCategoryId=${categoryId}`, {
          method: 'GET',
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          })
      });
      
 return fetch(request)
          .then((response) => response.json())
          .then((data) => {
            return data;
          })
          .catch((error) => {
            return error;
          });

}
export default fetchPrice;
