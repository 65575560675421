const phoneRegex = (number) => {
  if (number === "") {
    return true;
  } else {
    const validRegex =
      /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{2}[-\s\.]?[0-9]{2,15}$/;

    return number.match(validRegex) ? true : false;
  }
};

export default phoneRegex;
