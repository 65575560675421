import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { LabelText } from "../../LabelText/LabelText";
import styles from "./CompanyDetails.module.css";

const CompanyDetails = (props) => {
  const company = useSelector((state) => state.company);
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.main}>
        <div className={styles.title}>{t("company-title")}</div>
        <div className={styles.info}>
          <LabelText label={t("company-pib")} placeholder={t("company-pib")}>
            {company.pib}
          </LabelText>
          <LabelText label={t("company-name")} placeholder={t("company-name")}>
            {company.name}
          </LabelText>
          <LabelText label={t("company-address")} placeholder={t("company-address")}>
            {company.address}
          </LabelText>
          <LabelText label={t("company-email")} placeholder={t("company-email")}>
            {company.email}
          </LabelText>
          <LabelText label={t("company-registration-number")} placeholder={t("company-registration-number")}>
            {company.registrationNumber}
          </LabelText>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
