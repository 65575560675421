import CircularLoading from "../CircularLoading/CircularLoading";
import icons from "../../helpers/icons";
import styles from "./Button.module.css";
import { useTranslation } from "react-i18next";

// default:
// cancel (grey button)
// accepts props:
//  destructive/primary
//  icon="name" (eg. icon="add") OR icon={<svg>}
//  stretch
//  loading
//  right (moves svg position to end)

const Button = (props) => {
  const { t } = useTranslation();
  const buttonWidth = props.stretch ? ` ${styles.stretch}` : "";

  const buttonIcon = !props.children ? ` ${styles.icon}` : "";

  const buttonColor = props.destructive
    ? ` ${styles.destructive}`
    : props.primary
    ? ` ${styles.primary}`
    : ` ${styles.cancel}`;

  const buttonStyle =
    `${styles.baton}` + buttonColor + buttonIcon + buttonWidth;

  const renderLoading = () => {
    return (
      <>
        <CircularLoading transparent={props.primary || props.destructive} />
        {props.children && t('loading') + "..."}
      </>
    );
  };
  const renderSvg = () => {
    if (props.icon && props.icon.type === "svg") {
      return props.icon;
    } else {
      return icons[props.icon];
    }
  };
  const renderElements = () => {
    if (props.right) {
      return <>{props.children} {renderSvg()}</>;
    } else {
      return <>{renderSvg()} {props.children}</>;
    }
  };

  return (
    <button
      className={buttonStyle}
      id={props.id}
      name={props.name}
      type={props.type}
      onClick={!props.loading ? props.onClick : undefined}
      disabled={props.disabled}
    >
      {props.loading ? renderLoading() : renderElements()}
    </button>
  );
};

export default Button;
