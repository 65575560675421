import { t } from "i18next"
import { useTranslation } from "react-i18next";

import svg from "../../helpers/svg";
import Anchor from "../Anchor/Anchor";
import styles from "./Footer.module.css";

// firstRow creates a row for each { } which can contain:
// {
// image: {src: "link", alt: "alt-text", style: {display: "flex"} },
// header: "Title",
// lines: ["First line", "Second line"... "X line"],
// links: [
// { href: "/this-link", title: "Link Title" },
// ......
// { href: "/another-link", title: "Another Title"}
// ]
//
// secondRow contains svgs with or without links that can be grouped together
// const secondRow = [
// [ { svg: svg.maestro },
//   { svg: svg.americanexpress } ],
// [ { svg: svg.bancaintesa, href: "https://www.bancaintesa.rs/" } ],
// ]

const Footer = (props) => {
  const { i18n } = useTranslation();
  const firstRow = [
    {
      image: {
        src: `${process.env.REACT_APP_WP_URL}/wp-content/themes/event-manager/assets/img/logo-white.svg"`,
        alt: "logo",
        style: { width: "118px" },
      },
    },
    {
      header: t("bgmar-doo"),
      lines: [
        t("activity-and-code"),
        t("id-number"),
        t("tax-id"),
      ],
    },
    {
      header: t("contact"),
      lines: [
        t("address"),
        t("phone-numbers"),
      ],
      links: [
        {
          href: "mailto:" + t("bgmar-email"),
          title: t("bgmar-email"),
        },
      ],
    },{
      header: t("socials"),
      icons: [
        {
          href: `${process.env.REACT_APP_FACEBOOK_URL}`,
          svg: svg.facebook
        },
        {
          href: `${process.env.REACT_APP_INSTAGRAM_URL}`,
          svg: svg.instagram
        }
      ],
    },
    {
      header: t("links"),
      links: [
        { href: `${process.env.REACT_APP_WP_URL}/privacy-policy`, title: t("privacy-policy") },
        { href: `${process.env.REACT_APP_WP_URL}/terms-of-use`, title: t("terms-of-use") },
      ],
    },
  ];

  const secondRow = [
    [
      { svg: svg.cards.maestro },
      { svg: svg.cards.mastercard },
      { svg: svg.cards.dinacard },
      { svg: svg.cards.visa },
      { svg: svg.cards.americanexpress },
    ],
    [{ svg: svg.cards.bancaintesa, href: "https://www.bancaintesa.rs/" }],
    [
      {
        svg: svg.cards.visasecure,
        href: "https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html",
      },
      {
        svg: svg.cards.mastercardidcheck,
        href: "http://www.mastercard.com/rs/consumer/credit-cards.html",
      },
    ],
  ];
  const containerStyle = `${styles.container}`;
  const firstRowStyle = `${styles.rows} + ${styles.primary}`;
  const secondRowStyle = `${styles.rows} + ${styles.cards}`;
  const rowStyle = `${styles.row}`;
  const wrapperStyle = `${styles.wrapper}`;
  const copyrightStyle = `${styles.footer}`;

  return (
    <div className={containerStyle}>
      <div className={firstRowStyle}>
        <div className={wrapperStyle}>
          {firstRow.map((row, ind) => (
            <div key={`row-${ind}`} className={rowStyle}>
              <h4>{row.header}</h4>
              {row.image && (
                <a href={`${process.env.REACT_APP_WP_URL}?lang=${i18n.language}`}>
                  <img
                    alt={row.image.alt}
                    style={row.image.style}
                    src={row.image.src}
                  />
                </a>
              )}
              {row.icons &&  row.icons.map((icon, ind) =>(
                <a key={`icon-${ind}`} href={icon.href} target="_blank">
                  {icon.svg}
                </a>
              ))}
              {row.lines && row.lines.map((line, ind) => <li key={`line-${ind}`} className={styles.li}>{line}</li>)}
              {row.links &&
                row.links.map((link, ind) => (
                  <li key={`link-${ind}`} className={styles.li}>
                    <Anchor customClass={styles.a} href={link.href}>{link.title}</Anchor>
                  </li>
                ))}
            </div>
          ))}
        </div>
      </div>
      {secondRow && (
        <div className={secondRowStyle}>
          {secondRow.map((group, ind) => (
            <div key={`cardgroup-${ind}`} className={styles.cardgroup}>
              {group.map((row, ind) => (
                <div key={`card-${ind}`} className={styles.card}>
                  {(row.href && (
                    <Anchor href={row.href} customClass={styles.card}>
                      {row.svg}
                    </Anchor>
                  )) ||
                    row.svg}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      <div className={copyrightStyle}>
        <p>
          {t("copyright")}
        </p>
        <Anchor href="https://brightmarbles.io" title="BrightMarbles.io">
          {svg.brightmarbles}
        </Anchor>
      </div>
    </div>
  );
};

export default Footer;
