import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { successActions } from "../../store/slices/success-slice";

import Button from "../Button/Button";
import Input from "../Input/Input";
import styles from "./PasswordReset.module.css";

const PasswordResetForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [urlData, setUrlData] = useState({
    username: "",
    hash: "",
  });
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const validPassword = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("user") && params.get("hash")) {
      setUrlData((prevState) => {
        return {
          ...prevState,
          username: params.get("user"),
          hash: params.get("hash"),
        };
      });
    }
  }, [setUrlData]);

  const updatePassword = (e) => {
    setPassword(e.target.value);
  };

  const data = {
    confirmNewPassword: password,
    newPassword: password,
    hash: urlData.hash,
    username: urlData.username,
  };

  const reset = (e) => {
    e.preventDefault();

    if (!validPassword.test(password)) {
      setErrorMessage(t("password-policy") + ".");
      return;
    } else {
      setErrorMessage("");
    }

    fetch(`${process.env.REACT_APP_API_URL}/rest/jwt/password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          dispatch(successActions.setIsReset(true));
        }

        setIsLoading(false);
        return response.json();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={reset} className={styles["form"]}>
        <div className={styles["container"]}>
          <h2 className={styles["title"]}>{t("create-new-password")}</h2>
          <p> {t("password-suggestion")}</p>
          <div className={styles["input-container"]}>
            <Input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={updatePassword}
              errorMessage={errorMessage}
            />
          </div>
            <div className={styles["button"]}>
              <Button primary stretch loading={isLoading}>
                {" "}
                {t("create-new-password")}{" "}
              </Button>
            </div>
        </div>
      </form>
    </>
  );
};

export default PasswordResetForm;
