import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Anchor from "../Anchor/Anchor";
import styles from "./RegisterForm.module.css";

const SuccessfullRegistration = () => {
  const { t } = useTranslation();

  const username = useSelector((state) => state.registerData.username);

  const sendAgainHandler = (e) => {
    const data = {
      user: {
        username: username,
      },
    };

    fetch(`${process.env.REACT_APP_API_URL}/rest/jwt/resendConfirmationMail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error!");
        }
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className={styles["content"]}>
      <div className={styles["center-div"]}>
        <div className={styles.icon}> {successfulIcon}</div>
        <h1 className={styles["form-title"]}>{t("registration-successful")}!</h1>
        <p className={styles["form-text"]}>
          {t("sent-a-link")} <br />{" "}
          <span className={styles["email"]}>{username}</span>
        </p>
        <p className={styles["form-text"]}>
          {t("missing-mail")}?{" "}
          <Anchor className="primary" onClick={sendAgainHandler}>
            {t("send-again")}
          </Anchor>
        </p>
      </div>
    </div>
  );
};

export default SuccessfullRegistration;

const successfulIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#10B981"></circle>
    <path
      d="M32.25 18L20.25 30L15 24.75"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
