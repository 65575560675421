import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Anchor from "../Anchor/Anchor";

import styles from "./EventEnrollSuccessful.module.css";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../helpers/Cookie";

const EventEnrollSuccessful = () => {
  const runnerCookie = JSON.parse(getCookie('enrolledEvent'))
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [text, setText] = useState(t("enroll-success") + "!");

  useEffect(() => {}, [i18n.language]);

  const sendAgainMail = (e) => {
    
    fetch(
      `${process.env.REACT_APP_API_URL}/rest/jwt/runners/${runnerCookie.id}/resendEnrollmentMailNotification`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        },
      }
      )
      .then((response) => {
        if (!response.ok) {
          setText(t("email-not-resent") + "!");
          throw new Error("Couldn't send email again!");
        }
        return response.json();
      })
      .then((data) => setText(t("email-resent") + "."))
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={styles["container"]}>
        <div> {successfulIcon} </div>
        <div className="text-container">
          <div className={styles["form-title"]}>{text}</div>
          <div
            className={`${styles["form-text"]} ${styles["form-text-header"]}`}
          >
            <div className={styles.paragr1}>
              <p>{t("successfully-enrolled")}</p>
              <p> {t("event-details")}</p>
              <p>
                {" "}
                <b> {user.email} </b>{" "}
              </p>
            </div>
            <div className={styles.paragr2}>
              <p>
                {" "}
                {t("here-you-see") + " "}
                <Anchor className="primary" to="/event-details">
                  {t("your-enrolls")}.
                </Anchor>
              </p>
              <p>
                {" "}
                {t("missing-mail") + "? "}
                <Anchor className="primary" onClick={sendAgainMail}>
                  {t("send-again")}
                </Anchor>
              </p>
            </div>
            <div>
              <p onClick={() => navigate(-1)}>
                <Anchor href="#" className="primary">
                  {t("return-to-previous-page")}
                </Anchor>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventEnrollSuccessful;

const successfulIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#10B981"></circle>
    <path
      d="M32.25 18L20.25 30L15 24.75"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
