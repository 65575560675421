import React from "react";
import Error from "../../components/Error/Error";
import Footer from "../../components/Footer/Footer";
import Toolbar from "../../components/Toolbar/Toolbar";
import styles from './ErrorPage.module.css'

const ErrorPage = () => {
  return (
    <>
      <div className={styles["toolbar"]}>
        <Toolbar />
      </div>
      <Error />
      <Footer />
    </>
  );
};

export default ErrorPage;
