import React from "react";

import LoginForm from "../components/LoginForm/LoginForm";
import PreLoginLayout from "../components/Layout/PreLogin/PreLoginLayout";


const LoginPage = () => {


  return (
    <PreLoginLayout>
      <LoginForm />
    </PreLoginLayout>
  );
};

export default LoginPage;
