import React from "react";
import ReactDOM from "react-dom";

import styles from "./Modal.module.css";

const Backdrop = (props) => {
  return <div className={styles.backdrop} onClick={props.onBackdrop} />;
};

const ModalOverlay = (props) => {
  const modalStyle = () => {
    let style = `${styles.modal} `;
    if (props.customClass) {
      style += `${styles[props.customClass]}`;
    }
    return style;
  };

  return <div className={modalStyle()}>{props.children}</div>;
};

const portalElement = document.querySelector("#overlays");

const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onBackdrop={props.onBackdrop} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay customClass={props.customClass}>
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default Modal;
