import { createSlice } from "@reduxjs/toolkit";

const initialRecoveryState = {
  username: "",
};

const recoverySlice = createSlice({
  name: "recovery",
  initialState: initialRecoveryState,
  reducers: {
    recoverEmail(state, action) {
      state.username = action.payload;
    },
  },
});

export const recoveryActions = recoverySlice.actions;
export default recoverySlice.reducer;
