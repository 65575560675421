import React from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import TeamSuccess from "../../components/TeamSuccess/TeamSuccess";
import Toolbar from "../../components/Toolbar/Toolbar";
import styles from "./TeamSuccessful.module.css";

const TeamSuccessful = () =>{
    const user = useSelector((state) => state.user);
    return (
      <>
        <div className={styles["toolbar"]}>
          <Toolbar firstLetter={user.name[0]} />
        </div>
        <TeamSuccess/>
        <Footer />
      </>
    );
}

export default TeamSuccessful;