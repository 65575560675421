import styles from "./CircularLoading.module.css";

// default:
// small, primary
// accepts props:
// big
// transparent
// absolute

const CircularLoading = (props) => {
  const styleType = props.transparent
    ? ` ${styles.transparent}`
    : ` ${styles.primary}`;

  const styleSize = props.big ? ` ${styles.big}` : ` ${styles.small}`;

  const stylePosition = props.absolute ? ` ${styles.absolute}` : ``;

  const style = styles.loading + styleType + styleSize + stylePosition;

  return <div className={style}></div>;
};

export default CircularLoading;
