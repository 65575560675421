import { useState } from "react";

import Card from "../Card/Card";
import styles from "./Avatar.module.css";
import Dialog from "../Dialog/Dialog";
import Button from "../Button/Button";
import { eraseCookie, getCookie } from "../../helpers/Cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/slices/auth-slice";

// size="large" or "small"
// onClick={} will return event, eg. 'logout'

const Avatar = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const blurHandler = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowOptions(false);
    }
  };

  // POST method to invalidate JWT and clear login cookies and redirect to homepage
  const logoutUser = (e) => {
    const logoutJwt = getCookie("jwt");

    const logoutData = {
      token: logoutJwt,
    };

    if (logoutJwt) {
      fetch(`${process.env.REACT_APP_API_URL}/rest/jwt/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getCookie("jwt"),
        },
        body: JSON.stringify(logoutData),
      })
        .then((response) => {
          if (response.ok && response.status === 200) {
            eraseCookie("jwt");
            eraseCookie("userLogged");
            if (getCookie("enrolledEvent")) {
              eraseCookie("enrolledEvent");
            }
            dispatch(authActions.logout());
            navigate("/", { replace: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const avatarStyle = props.size
    ? `${styles.avatar} ${styles[props.size]}`
    : `${styles.avatar} ${styles.small}`;

  const innerStyle = props.size
    ? `${styles.inner} ${styles[props.size]}`
    : `${styles.inner} ${styles.small}`;

  const optionMenuStyle = props.size
    ? `${styles.avatarOptionsContainer} ${styles[props.size]}`
    : `${styles.avatarOptionsContainer} ${styles.small}`;

  const avatarOptionStyle = styles.avatarOption;

  return (
    <>
      <div className={styles["modal"]}>
        {isModalShown && (
          <Dialog
            onBackdrop={() => setIsModalShown(false)}
            title={t("signout")}
            text={t("are-you-sure-about-signout") + "?"}
            buttons={
              <>
                <Button onClick={() => setIsModalShown(false)}>
                  {t("cancel")}
                </Button>
                <Button destructive onClick={logoutUser}>
                  {t("signout")}
                </Button>
              </>
            }
          ></Dialog>
        )}
      </div>
      <span
        className={avatarStyle}
        tabIndex="-1"
        onBlur={blurHandler}
        onClick={() => setShowOptions(!showOptions)}
      >
        <div className={innerStyle}>{props.children}</div>
        <Card
          className={optionMenuStyle}
          style={{ display: showOptions ? "flex" : "none" }}
        >
          <div
            className={avatarOptionStyle}
            onClick={() => setIsModalShown(true)}
          >
            {logoutIcon}
            {t("signout")}
          </div>
        </Card>
      </span>
    </>
  );
};

export default Avatar;

const logoutIcon = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20.5C11 19.9477 10.5523 19.5 10 19.5H5V5.5H10C10.5523 5.5 11 5.05228 11 4.5C11 3.94771 10.5523 3.5 10 3.5H5C3.89543 3.5 3 4.39543 3 5.5V19.5C3 20.6046 3.89543 21.5 5 21.5H10C10.5523 21.5 11 21.0523 11 20.5Z"
      fill="#9CA3AF"
    ></path>
    <path
      d="M21.7136 13.2005C21.8063 13.1062 21.8764 12.998 21.9241 12.8828C21.9727 12.7657 21.9996 12.6375 22 12.503L22 12.5L22 12.497C21.9992 12.2421 21.9016 11.9874 21.7071 11.7929L17.7071 7.79289C17.3166 7.40237 16.6834 7.40237 16.2929 7.79289C15.9024 8.18342 15.9024 8.81658 16.2929 9.20711L18.5858 11.5H9C8.44771 11.5 8 11.9477 8 12.5C8 13.0523 8.44771 13.5 9 13.5H18.5858L16.2929 15.7929C15.9024 16.1834 15.9024 16.8166 16.2929 17.2071C16.6834 17.5976 17.3166 17.5976 17.7071 17.2071L21.7064 13.2078L21.7136 13.2005Z"
      fill="#9CA3AF"
    ></path>
  </svg>
);
