import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import countries from "../../helpers/countries.json";
import { userActions } from "../../store/slices/user-slice";
import { LabelText } from "../LabelText/LabelText";
import Input from "../Input/Input";

import Select from "../Select/Select";
import styles from "./UserInfo.module.css";
import phoneRegex from "../../helpers/phoneRegex";

const UserInfo = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.user);
  const [city, setCity] = useState(user.city);
  const [phoneNum, setPhoneNum] = useState(user.phoneNum);
  const [country, setCountry] = useState(countries[`${user.country}`]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setCity(user.city);
    setPhoneNum(user.phoneNum);
    setCountry(countries[`${user.country}`]);
    dispatch(userActions.reset());
  }, [i18n.language]);

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  const cityHandler = (e) => {
    setCity(e.target.value);
    dispatch(
      userActions.updateField({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };

  const phoneNumHandler = (e) => {
    setPhoneNum(e.target.value);
    dispatch(
      userActions.updateField({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };

  const countryHandler = (e) => {
    setCountry(e.target.visibleValue);
    dispatch(
      userActions.updateField({
        name: e.target.name,
        value: e.target.value,
      })
    );
  };

  const checkGender = () => {
    if (user.gender === "FEMALE") {
      return t("female");
    } else if (user.gender === "MALE") {
      return t("male");
    } else {
      return "";
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.title}>{t("user-info")}</div>
      <div className={styles.info}>
        <LabelText label={t("firstname")} placeholder={t("firstname")}>
          {user.name}
        </LabelText>

        <LabelText label={t("lastname")} placeholder={t("lastname")}>
          {user.lastname}
        </LabelText>

        <LabelText label={t("date-of-birth")} placeholder={t("date-of-birth")}>
          {user.dateOfBirth}
        </LabelText>

        <LabelText label={t("gender")} placeholder={t("gender")}>
          {checkGender()}
        </LabelText>

        <Select
          preselect={countries[`${user.country}`] || country}
          placeholder={t("country")}
          name="country"
          values={countries}
          onChange={(e) => countryHandler(e)}
        />

        <Input
          errorMessage=""
          name="city"
          value={user.city || city}
          placeholder={t("city")}
          onChange={(e) => cityHandler(e)}
        />

        <Input
          errorMessage={errors["phone"] && !phoneRegex(phoneNum) && t("phone-invalid")}
          name="phoneNum"
          type="number"
          value={user.phoneNum || phoneNum}
          placeholder={t("phone-number")}
          onChange={(e) => phoneNumHandler(e)}
        />

        <LabelText label="Email*" placeholder="Email">
          {user.email}
        </LabelText>
      </div>
    </div>
  );
};

export default UserInfo;
