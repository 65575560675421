import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Anchor from "../Anchor/Anchor";
import Avatar from "../Avatar/Avatar";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import LanguageChip from "../LanguageChip/LanguageChip";

import styles from "./Toolbar.module.css";

const Toolbar = (props) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.toolbar}>
      <a href={`${process.env.REACT_APP_WP_URL}?lang=${i18n.language}`}>
        <img
          className={styles.logo}
          alt="logo"
          src={process.env.REACT_APP_WP_URL + "/wp-content/themes/event-manager/assets/img/logo.svg"}
        />
      </a>
      <div className={styles.right}>
        {isLoggedIn && 
        <div className={styles.links}>
          <Anchor customClass={styles.link} href={`${process.env.REACT_APP_TOOLBAR_EVENTS}?lang=${i18n.language}`}> {t("toolbar-events")} </Anchor>
          <Anchor customClass={styles.link} href={`${process.env.REACT_APP_TOOLBAR_NEWS}?lang=${i18n.language}`}> {t("toolbar-news")} </Anchor>
          <Anchor customClass={styles.link} href={`${process.env.REACT_APP_TOOLBAR_RESULTS}?lang=${i18n.language}`}> {t("toolbar-results")} </Anchor>
          <Anchor customClass={styles.link} href={`${process.env.REACT_APP_TOOLBAR_CONTACT}?lang=${i18n.language}`}> {t("toolbar-contact")} </Anchor>
        </div>
        }
        {!props.hideBurger &&
        <div className={styles.burger}>
          <HamburgerMenu> 
            <Anchor customClass={styles.link} href={process.env.REACT_APP_TOOLBAR_EVENTS}> {t("toolbar-events")} </Anchor>
            <Anchor customClass={styles.link} href={process.env.REACT_APP_TOOLBAR_NEWS}> {t("toolbar-news")} </Anchor>
            <Anchor customClass={styles.link} href={process.env.REACT_APP_TOOLBAR_RESULTS}> {t("toolbar-results")} </Anchor>
            <Anchor customClass={styles.link} href={process.env.REACT_APP_TOOLBAR_CONTACT}> {t("toolbar-contact")} </Anchor>
          </HamburgerMenu>
        </div>}
        <LanguageChip />
        {isLoggedIn && <Avatar>{props.firstLetter}</Avatar>}
      </div>
    </div>
  );
};

export default Toolbar;
