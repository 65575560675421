import React from "react";
import { useSelector } from "react-redux";
import PreLoginLayout from "../components/Layout/PreLogin/PreLoginLayout";
import PasswordResetForm from "../components/PasswordReset/PasswordResetForm";
import PasswordResetSuccessful from "../components/PasswordReset/PasswordResetSuccessful";

const PasswordReset = () => {
  const isPasswordReseted = useSelector(
    (state) => state.success.isPasswordReset
  );

  return (
    <PreLoginLayout>
      {!isPasswordReseted && <PasswordResetForm />}
      {isPasswordReseted && <PasswordResetSuccessful />}
    </PreLoginLayout>
  );
};

export default PasswordReset;
