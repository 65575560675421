import { useState } from "react";
import Select from "../Select/Select";

import styles from "./InputDatePickerSimple.module.css";

const InputDatePickerSimple = (props) => {
  const [date, setDate] = useState({});

  const days = [
    "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
    "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
    "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
    "31",
  ];
  const months = [
    "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
    "11", "12",
  ];
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
    "Nov", "Dec",
  ];
  const getYears = () => {
    let currentYear = new Date().getFullYear();
    let years = [];
    let oldestPossibleYear = 1905;
    for (let x = 0; currentYear - x >= oldestPossibleYear; x++) {
      years.push(currentYear - x);
    }
    return years;
  };
  const changeHandler = (e) => {
    let newDate = {...date, [e.target.name]: e.target.value};
    setDate(newDate);
    if (Object.keys(newDate).length === 3) {
      props.onSelect(`${newDate.year}-${newDate.month}-${newDate.day}T00:00:00Z`);
    }
  };

  const inputStyle = !props.errorMessage
    ? `${styles.input}`
    : `${styles.input} ${styles["error-border"]}`;

  const labelStyle = props.errorMessage
    ? `${styles.label} ${styles.error}`
    : `${styles.label}`;

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <label
          className={labelStyle}
        >
          {props.label}
        </label>
        <div className={inputStyle}>
          <Select
            name="day"
            readOnly={true}
            values={days}
            placeholder={"D"}
            disableLabel
            disableError
            onChange={changeHandler}
          />
          <Select
            name="month"
            values={months}
            visibleValues={monthNames}
            readOnly={true}
            placeholder={"M"}
            disableLabel
            disableError
            onChange={changeHandler}
          />
          <Select
            name="year"
            values={getYears()}
            readOnly={true}
            placeholder={"Y"}
            disableLabel
            disableError
            onChange={changeHandler}
          />
        </div>
      </div>
      <div className={`${styles["input-container-error"]} ${styles.error}`}>
        {props.errorMessage}
      </div>
    </div>
  );
};

export default InputDatePickerSimple;
