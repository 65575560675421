import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { paymentActions } from "../../store/slices/payment-slice";
import { eventActions } from "../../store/slices/event-slice";
import { generateQR } from "../../helpers/generateQR";

import Select from "../Select/Select";
import Button from "../Button/Button";
import Anchor from "../Anchor/Anchor";
import Checkbox from "../Checkbox/Checkbox";
import styles from "./PaymentInfo.module.css";
import PaymentSlip from "../PaymentSlip/PaymentSlip";

const PaymentInfo = (props) => {
  const { t } = useTranslation();

  const event = useSelector((state) => state.event);
  const payment = useSelector((state) => state.payment);
  const runner = useSelector((state) => state.runner);
  const isSubmitting = useSelector((state) => state.auth.isSubmittingRunner);
  const isAgeLimitError = useSelector((state) => state.errors.ageLimitError);
  const dispatch = useDispatch();

  const [showPaymentSlip, setShowPaymentSlip] = useState(false);
  const [qrSrc, setQrSrc] = useState();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (
      (props.preselectMethod === "IPS" &&
        runner.paymentStatus === "WAITING_VALIDATION") ||
      (payment.selectedMethod.value === "IPS" &&
        event.priceRsd &&
        Object.keys(event.selectedCategory).length !== 0)
    ) {
      generateQR(
        props.user,
        parseInt(event.priceRsd),
        event.selectedEvent.nameSr,
        event.selectedCategory.id
      ).then((response) => {
        setQrSrc(response);
      });
    } else {
      setQrSrc();
    }
  }, [
    payment.selectedMethod.value,
    props.preselectMethod,
    runner.paymentStatus,
    event.priceRsd,
    event.selectedCategory,
    event.selectedEvent,
    props.user,
    setQrSrc,
  ]);

  const selectHandler = (e) => {
    dispatch(
      paymentActions.selectPaymentMethod({
        value: e.target.value,
        name: e.target.visibleValue,
      })
    );
  };

  const toggleCheckbox = (checked, e) => {
    dispatch(paymentActions.toggle(e.target.name));
  };

  const disablePaymentMethod = () => {
    if (payment.isMethodChangable) {
      return "";
    }
    if (
      Object.keys(event.selectedCategory).length === 0 ||
      event.alreadyHasData
    ) {
      return "true";
    }
  };

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  const isButtonShown =
    !event.alreadyHasData || payment.isMethodChangable ? true : false;

  const isValidated =
    runner.paymentStatus === "PAID" || runner.paymentStatus === "GRATIS"
      ? true
      : false;

  const isRefund = runner.paymentStatus === "REFUND" ? true : false;

  const paymentMethodError =
    errors.paymentMethod &&
    `${t("field")} ${t("payment-method")} ${t("cant-be-empty")}`;

  return (
    <div className={styles.main}>
      <div className={styles.title}>{t("payment-info")}</div>
      <div className={styles.info}>
        {event.alreadyHasData && (isValidated || isRefund || props.isCorporate) ? (
          <p>
           {t('payment-status')} :
            <p className={styles.paymentStatus}>{runner.paymentStatus}</p>
          </p>
        ) : (
          <>
            <Select
              name="podaci o placanju"
              disabled={disablePaymentMethod()}
              preselect={props.preselectMethod}
              onChange={selectHandler}
              placeholder={t("payment-method")}
              values={payment.valuesMethod}
              errorMessage={paymentMethodError}
            />
            <div className={styles["total-sum"]}>
              <div>{t("total")} </div>
              <div>
                {event.priceRsd} RSD [{event.priceEur} EUR]
              </div>
            </div>
          </>
        )}

        {payment.selectedMethod.value === "FP" && !isValidated && !isRefund && !props.isCorporate && fpContent}
        {payment.selectedMethod.value === "IPS" && !isValidated && !isRefund && !props.isCorporate && qrSrc && (
          <div style={{display: "flex", justifyContent: "center"}}>
            <img
              alt="QR-Code"
              src={qrSrc}
              style={{ display: "inline-block", width: "100%", maxWidth: "250px" }}
            />
          </div>
        )}
         {payment.selectedMethod.value === "BT" && !isValidated && !isRefund && !props.isCorporate && (
          <>
            <svg version="1.1" viewBox="0 0 650 323">
              <image
                width="650"
                height="323"
                href={
                  process.env.REACT_APP_WP_URL +
                  "/wp-content/themes/event-manager/assets/img/uplatnica.png"
                }
              ></image>
              <foreignObject name="payer" x="35" y="56" width="350" height="30">
                <p>{props.user.name + " " + props.user.lastname}</p>
              </foreignObject>
              <foreignObject
                name="purpose-of-payment"
                x="35"
                y="116"
                width="350"
                height="50"
              >
                <p>{`Prijava za trku`}</p>
              </foreignObject>
              <foreignObject
                name="recipient"
                x="35"
                y="176"
                width="350"
                height="30"
              >
                <p>Beogradski maraton d.o.o.</p>
              </foreignObject>
              <foreignObject
                name="payment-code"
                x="413"
                y="59"
                width="200"
                height="30"
              >
                <p>289</p>
              </foreignObject>
              <foreignObject
                name="amount"
                x="483"
                y="59"
                width="200"
                height="30"
              >
                <p>{t("check-price-short")}</p>
              </foreignObject>
              <foreignObject
                name="recipient-account"
                x="409"
                y="96"
                width="255"
                height="30"
              >
                <p>160-6000001609055-89</p>
              </foreignObject>
              <foreignObject
                name="model"
                x="366"
                y="134"
                width="255"
                height="30"
              >
                <p>00</p>
              </foreignObject>
              <foreignObject
                name="reference-number"
                x="409"
                y="134"
                width="255"
                height="30"
              >
                <p>
                  {event.alreadyHasData
                    ? runner.paymentId
                    : `${event.selectedCategory.id}-${props.user.id}`}
                </p>
              </foreignObject>
            </svg>
            <div className={styles["payment-slip-button"]}>
              {zoomIn}
              <p onClick={() => setShowPaymentSlip(true)}>
                {t("click-to-zoom")}
              </p>
            </div>
          </>
        )}
        {event.alreadyHasData && (
          <div className={styles["already-registered-for-event"]}>
            <br />
            <div>
              {t("already-enrolled") + ". " + t("here-you-see")}{" "}
              <Anchor
                to="/event-details"
                onClick={() => dispatch(eventActions.reset())}
                className="primary"
              >
                {t("your-enrolls")}.
              </Anchor>
            </div>
            <br />
            {!props.isCorporate && !isValidated && !isRefund ? (
              <Checkbox
                name="methodChange"
                onChange={toggleCheckbox}
                checked={payment.isMethodChangable}
              >
                {payment.valuesMethod.count > 1 ? t("want-to-change-payment-method") : t("want-to-continue-with-payment")}

              </Checkbox>
            ) : null}
          </div>
        )}
       
        {showPaymentSlip && (
          <PaymentSlip
            name={props.user.name}
            lastname={props.user.lastname}
            event={event.selectedEvent.nameSr}
            category={event.selectedCategory.mnemonic}
            paymentId={
              event.alreadyHasData
                ? runner.paymentId
                : `${event.selectedCategory.id}-${props.user.id}`
            }
            onClose={() => setShowPaymentSlip(false)}
          />
        )}

        {isButtonShown && (
          <Button
            onClick={props.onSubmit}
            primary
            stretch
            loading={isSubmitting}
          >
            {t("enroll")}
          </Button>
        )}
        {isAgeLimitError && <div className={styles['error-text']}>
          {t("user-under-age-limit-error")}
        </div>}
      </div>
    </div>
  );
};

export default PaymentInfo;

const fpContent = (
  <div className={styles["foreign-payment-container"]}>
    <div className={styles.intermediary}>
      <p>Intermediary:</p>
      <p>
        BCITITMM
        <br />
        INTESA SANPAOLO SPA
        <br />
        MILANO, ITALY
      </p>
    </div>
    <div className={styles["acc-institution"]}>
      <p>Account with institution:</p>
      <p>
        DBDBRSBG
        <br />
        BANCA INTESA AS, BEOGRAD
        <br />
        MILENTIJA POPOVIĆA 7B
        <br />
        BEOGRAD, REPUBLIKA SRBIJA
      </p>
    </div>
    <div className={styles.beneficiary}>
      <p>Beneficiary:</p>
      <p>
        /RS35160005390001265511
        <br />
        BEOGRADSKI MARATON
        <br />
        DR IVE POPOVICA DJANIJA 3A
        <br />
        Beograd (Savski Venac)
        <br />
        Republic of Serbia
      </p>
    </div>
  </div>
);

const zoomIn = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.8487 17.3729 13.551 16.3199 14.9056L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9056 16.3199C13.551 17.3729 11.8487 18 10 18C5.58172 18 2 14.4183 2 10ZM10 7C10.5523 7 11 7.44772 11 8V9H12C12.5523 9 13 9.44772 13 10C13 10.5523 12.5523 11 12 11H11V12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12V11H8C7.44772 11 7 10.5523 7 10C7 9.44772 7.44772 9 8 9H9V8C9 7.44772 9.44772 7 10 7Z"
      fill="#9CA3AF"
    />
  </svg>
);
