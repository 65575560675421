import React from "react";

import Toolbar from "../../components/Toolbar/Toolbar";
import Footer from "../../components/Footer/Footer";
import Anchor from "../../components/Anchor/Anchor";
import styles from "./PrivacyPolicy.module.css";

const PrivatePolicy = () => {
  return (
    <>
      <div className={styles["toolbar"]}>
        <Toolbar />
      </div>
      <div className={styles["page-container"]}>
        <div className={styles["content-container"]}>
          <h1>
            INFORMACIJE O PRIKUPLJANJU I OBRADI PODATAKA O LIČNOSTI (POLITIKA
            PRIVATNOSTI)
          </h1>
          <p className={styles["para"]}></p>

          <h2 className={styles["subtitle"]}>RUKOVALAC</h2>
          <p className={styles["para"]}>
            Mi smo BEOGRADSKI MARATON PROIZVODNJA, MARKETING I ORGANIZACIJA
            SPORTSKIH PRIREDBI DOO, BEOGRAD, sa registrovanim sedištem na adresi
            Dr Ive Popovića Đanija 3a, Beograd (Savski venac), Republika Srbija,
            matični broj 06303773 (u daljem tekstu:{" "}
            <span className={styles["bold"]}>"Beogradski maraton"</span> ili
            <span className={styles["bold"]}>"Rukovalac" </span>ili
            <span className={styles["bold"]}>"Mi"</span>).
          </p>
          <p></p>
          <p className={styles["para"]}>
            Beogradski maraton je svestan i razume da je privatnost od velike
            važnosti za Vas, tako da zaštitu podataka o ličnosti shvatamo veoma
            ozbiljno. Stoga, kao Rukovalac i kreiranjem ove Politike
            privatnosti, obradu Vaših podataka o ličnosti činimo transparentnom
            i razumljivom. Rukovalac je lice koje određuje svrhu i način obrade
            podataka, a u slučaju kada dva ili više rukovaoca zajednički
            određuju svrhu i način obrade, onda se oni smatraju zajedničkim
            rukovaocima.
          </p>
          <p className={styles["para"]}>
            U ovoj Politici privatnosti objašnjavamo kako, u svojstvu Rukovaoca,
            prikupljamo, obrađujemo i koristimo Vaše podatke o ličnosti, kao i
            kako možete da ostvarite prava koja imate kao subjekt podataka. Ova
            Politika privatnosti se odnosi na sva fizička lica koji pristupaju
            našoj veb stranici{" "}
            <Anchor className="primary">www.bgdmarathon.org</Anchor> (u daljem
            tekstu: <span className={styles["bold"]}>Veb stranica</span>),
            registruju profil ili se prijavljuju za trke koje organizuje
            Beogradski maraton, itd.
          </p>
          <p className={styles["para"]}>
            Ova Politika privatnosti, zajedno sa našim
            <Anchor className="primary">
              {" "}
              Opštim uslovima i Politikom o kolačićima
            </Anchor>
            , sadrže informacije koje se odnose ili mogu se odnositi na Vas.
            Stoga Vam predlažemo da ih pažljivo pročitate kako biste saznali sve
            potrebne informacije u vezi sa obradom i zaštitom Vaših podataka o
            ličnosti.
          </p>
          <p className={styles["para"]}>
            U slučaju da imate bilo kakva pitanja u vezi sa ovom Politikom
            privatnosti, kontaktirajte nas na [●].
          </p>

          <h2 className={styles["subtitle"]}>
            PODACI O LIČNOSTI KOJE PRIKUPLJAMO
          </h2>
          <p className={styles["para"]}>
            Podatak o ličnosti je svaki podatak koji se odnosi na fizičko lice
            čiji je identitet određen ili odrediv, neposredno ili posredno,
            posebno na osnovu oznake identiteta, kao što je ime i
            identifikacioni broj, podataka o lokaciji, identifikatora u
            elektronskim komunikacionim mrežama ili jednog, odnosno više
            obeležja njegovog fizičkog, fiziološkog, genetskog, mentalnog,
            ekonomskog, kulturnog i društvenog identiteta.
          </p>
          <p className={styles["para"]}>
            Beogradski maraton može prikupljati, obrađivati, čuvati i prenositi
            različite vrste Vaših podataka o ličnosti koje smo grupisali na
            sledeći način:
          </p>
          <p className={styles["para"]}>
            "Podaci o identitetu" uključuje ime i prezime, korisničko ime ili
            sličan identifikator, pol, datum rođenja, grad, državljanstvo,
            fotografije, video zapise.
          </p>
          <p className={styles["para"]}>
            "Kontakt podaci" uključuje adresu elektronske pošte i brojeve
            telefona.
          </p>
          <p className={styles["para"]}>
            "Podaci o transakcijama" uključuje detalje o plaćanjima koje je
            izvršili prema nama (npr. prilikom prijavljivanja na naše događaje i
            trke).
          </p>
          <p className={styles["para"]}>
            "Podaci o profilu" uključuje prijave koje ste napravili za učešće na
            našim događajima i trkama, distancu trke za koju se prijavljujete,
            veličinu majice, Vaše očekivano vreme trke, ostvareni učinak,
            rezultate i vreme na našim trkama.
          </p>
          <p className={styles["para"]}>
            "Marketinški podaci" uključuje vaše preferencije u smislu
            prihvatanja marketinga od nas i trećih strana.
          </p>
          <p className={styles["para"]}>
            "Tehnički podaci" uključuju adresu internet protokola (IP), tip i
            verziju pregledača i druge podatke / tehnologije na uređajima koje
            koristite za pristup našoj Veb stranici.
          </p>

          <h2 className={styles["subtitle"]}>
            KAKO PRIKUPLJAMO VAŠE PODATKE O LIČNOSTI
          </h2>
          <p className={styles["para"]}>
            Koristimo različite metode za prikupljanje podataka od Vas i o Vama,
            uključujući:
          </p>
          <p className={styles["para"]}>
            <span className={styles["underline"]}>Direktna interakcija.</span>{" "}
            Podatke o identitetu, Kontakt podatke i druge podatke navedene u
            prethodnom odeljku dajete prilikom kreiranja / registracije profila
            na našoj Veb stranici odnosno prilikom prijavljivanja na događaje
            ili trke koje organizujemo, prilikom davanja pristanka za
            newslettere i druge promotivne aktivnosti.
          </p>
          <p className={styles["para"]}>
            <span className={styles["underline"]}>
              Automatizovane tehnologije ili interakcije.
            </span>{" "}
            Dok ostvarujete interakciju sa Veb stranicom, možemo automatski
            prikupljati tehničke podatke o Vašoj opremi, radnjama pregledanja i
            obrascima ponašanja. Ove podatke o ličnosti prikupljamo korišćenjem
            kolačića, serverskih evidencija i drugih sličnih tehnologija. Molimo
            pogledajte našu Politiku kolačića za više detalja.
          </p>
          <p className={styles["para"]}>
            <span className={styles["underline"]}>
              Realizacija događaja i trka Beogradskog maratona.
            </span>{" "}
            Vaše podatke o ličnosti prikupljamo i obrađujemo, samostalno ili
            preko angažovanih obrađivača, u sklopu Vašeg učešća na događajima i
            trkama koje organizujemo (npr. fotografije i video zapisi, merenje
            vremena, ostvareni rezultati i rangiranje).
          </p>
          <p className={styles["para"]}>
            <span className={styles["underline"]}>Treća lica.</span> Možemo da
            primamo Vaše podatke o ličnosti od različitih trećih strana. U tom
            slučaju, informacije o obradi Vaših podataka o ličnosti dostavićemo
            Vam u razumnom roku i u skladu sa Zakonom, osim kada je Zakonom
            isključena takva obaveza.
          </p>

          <h2 className={styles["subtitle"]}>
            KAKO KORISTIMO VAŠE PODATKE O LIČNOSTI
          </h2>
          <p className={styles["para"]}>
            Vaše lične podatke ćemo koristiti samo kada i kako nam to Zakon
            dozvoljava. Najčešće ćemo koristiti Vaše podatke o ličnosti u
            sledećim pravnim osnovima: a) kada treba da izvršimo ugovor koji
            ćemo uskoro zaključili ili smo zaključili sa Vama, b) kada je to
            neophodno za naše legitimne interese (ili interese treće strane) a
            Vaši interesi i osnovna prava ne prevladavaju nad tim interesima, c)
            kada smo dobili Vaš punovažan pristanak za obradu podataka o
            ličnosti, d) kada treba da ispunimo zakonsku ili drugu pravnu
            obavezu.
          </p>
          <p className={styles["para"]}>
            Kontaktirajte nas da biste saznali više o pravnim osnovama koje ćemo
            primenjivati za obradu Vaših podataka o ličnosti.
          </p>

          <h2 className={styles["subtitle"]}>
            SVRHE U KOJE KORISTIMO VAŠE PODATKE O LIČNOSTI
          </h2>
          <p>
            (moramo imati mogućnost da objavljujemo i prikazujemo naše trke
            putem štampanih i elektronskih medija, na Veb stranici i društvenim
            mrežama, kao i drugim medijima sadašnjim ili budućim) Vaš pristanak
          </p>

          <table className={styles["table"]}>
            <thead className={styles["head"]}>
              <tr>
                <th>Svrha / Aktivnost</th>
                <th>Vrsta podataka o ličnosti</th>
                <th>Pravni osnov</th>
                <th>Period čuvanja</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="4" className={styles["border"]}>
                  Kreiranje (registracija) profila na Veb stranici{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o identitetu{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Vaš pristanak
                </td>
                <td rowspan="4" className={styles["border"]}>
                  Dok je profil aktivan (nije obrisan od strane korisnika)
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Kontakt podaci{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Vaš pristanak
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o profilu{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Vaš pristanak
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Marketinški podaci{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Vaš pristanak
                </td>
              </tr>
              <tr>
                <td rowspan="4" className={styles["border"]}>
                  Prijava za trke koje organizuje Rukovalac{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o identitetu{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Izvršenje ugovora ili preduzimanje radnji, na Vaš zahtev, pre
                  zaključenja ugovora{" "}
                </td>
                <td rowspan="4" className={styles["border"]}>
                  Do ostvarenja svrhe, a najduže do jedne godine
                </td>
              </tr>

              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Kontakt podaci{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Izvršenje ugovora ili preduzimanje radnji, na Vaš zahtev, pre
                  zaključenja ugovora
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o transakcijama{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Izvršenje ugovora ili preduzimanje radnji, na Vaš zahtev, pre
                  zaključenja ugovora
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o profilu{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Izvršenje ugovora ili preduzimanje radnji, na Vaš zahtev, pre
                  zaključenja ugovora
                </td>
              </tr>
              <tr>
                <td rowspan="3" className={styles["border"]}>
                  Realizacija trka i objavljivanje rezultata{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o identitetu{" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  Izvršenje ugovora, Legitiman interes Rukovaoca (moramo imati
                  mogućnost da objavljujemo i čuvamo rezultate, rangiramo
                  učesnike trka i slično){" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  Trajno
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o profilu
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Kontakt podaci
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Snimanje (video i foto) i objavljivanje materijala sa trka
                  koje organizuje Rukovalac{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Video zapisi, Fotografije{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Legitiman interes Rukovaoca{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Trajno
                </td>
              </tr>

              <tr>
                <td rowspan="3" className={styles["border"]}>
                  Dostavljanje Vaših podataka organizaciji Abbott World Marathon
                  Majors (Sjedinjene Američke Države) radi Vaše selekcije i
                  kvalifikovanja za vodeće svetske maratone (Tokio, Boston,
                  London, Berlin, Čikago, Njujork) u master kategorijama
                  takmičenja{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  {" "}
                  Podaci o identitetu{" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  {" "}
                  Vaš pristanak{" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  Do opoziva pristanka
                </td>
              </tr>
              <tr>
                <td rowspaw="1" className={styles["border"]}>
                  Kontakt podaci
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o profilu
                </td>
              </tr>
              <tr>
                <td rowspan="3" className={styles["border"]}>
                  Uključivanje na liste za naše newslettere i druge promotivne
                  aktivnosti{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o identitetu{" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  Vaš pristanak{" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  Do opoziva pristanka
                </td>
              </tr>

              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Kontakt podaci
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Marketing podaci
                </td>
              </tr>
              <tr>
                <td rowspan="3" className={styles["border"]}>
                  Dostavljanje Vaših podataka trećim licima u svrhe marketinga{" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Podaci o identitetu{" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  Vaš pristanak{" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  Do opoziva pristanka
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Kontakt podaci
                </td>
              </tr>
              <tr>
                <td rowspan="1" className={styles["border"]}>
                  Marketinh podaci
                </td>
              </tr>
              <tr>
                <td rowspan="3" className={styles["border"]}>
                  Korišćenje kolačića (cookies) (videti Politiku kolačića){" "}
                </td>
                <td rowspan="1" className={styles["border"]}>
                  Tehnički podaci{" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  Vaš pristanak{" "}
                </td>
                <td rowspan="3" className={styles["border"]}>
                  Do opoziva pristanka
                </td>
              </tr>
            </tbody>
          </table>

          <p className={styles["para"]}>
            U slučaju da Vaše podatke o ličnosti nameravamo da obrađujemo u
            druge svrhe u odnosu na gore navedene, tada ćemo Vam pružiti sve
            potrebne informacije u skladu sa Zakonom pre započinjanja takve
            obrade.
          </p>

          <h2 className={styles["subtitle"]}>
            KOLIKO DUGO ČUVAMO VAŠE PODATKE O LIČNOSTI
          </h2>
          <p className={styles["para"]}>
            Vaše podatke o ličnosti ćemo čuvati samo onoliko dugo koliko je
            potrebno da ispunimo svrhe za koje smo ih prikupili, uključujući i
            svrhe ispunjavanja zakonskih obaveza ili drugih pravnih zahteva.
            Prilikom određivanja odgovarajućeg perioda čuvanja podataka o
            ličnosti, uzimamo u obzir količinu, prirodu i osetljivost podataka o
            ličnosti, potencijalni rizik od štete od neovlašćene upotrebe ili
            otkrivanja podataka o ličnosti, svrhe u koje obrađujemo podatke o
            ličnosti i da li te svrhe možemo postići drugim sredstvima i u
            skladu sa važećim propisom. Detalje o periodima čuvanja za različite
            svrhe Vaših podataka o ličnosti navedeni su u tabeli u okviru
            odeljka "Svrhe u koje koristimo Vaše podatke o ličnosti".
          </p>

          <h2 className={styles["subtitle"]}>MARKETING</h2>
          <p className={styles["para"]}>
            Nastojimo da Vam pružimo izbor, u vezi sa korišćenjem Vaših podataka
            o ličnosti, posebno u vezi sa marketingom i oglašavanjem.
          </p>
          <p className={styles["para"]}>
            Koristićemo Vaše podatke o identitetu, Kontakt podatke i Marketing
            podatke za slanje naših newslettera i drugih promotivnih sadržaja,
            ukoliko ste nam dali Vaš pristanak (opt-in) za ove svrhe.
            Istovremeno, pribavićemo Vaš pristanak pre nego što podelimo Vaše
            podatke o ličnosti sa trećim licima (kompanijama) u svrhe
            marketinga.
          </p>
          <p className={styles["para"]}>
            Možete da zatražite od nas ili trećih lica da prestanemo da Vam
            šaljemo marketinške poruke u bilo kom trenutku (opoziv pristanka).
            Opoziv pristanka može učiniti tako što ćete se prijaviti na Veb
            stranicu i opozvati relevantna polja za podešavanje marketinških
            postavki u okviru Vašeg profila (opt-out) ili tako što ćete pratiti
            veze za odustajanje na bilo kojoj marketinškoj poruci koja Vam je
            poslata ili kontaktiranjem nas ili trećeg lica u bilo koje vreme.
          </p>

          <h2 className={styles["subtitle"]}>PRIMAOCI, KATEGORIJE PRIMALACA</h2>
          <p className={styles["para"]}>
            Beogradski maraton može Vaše podatke o ličnosti dostaviti
            obrađivačima sa kojima ima zaključene ugovore o obradi podataka i
            koji obrađuju Vaše podatke u ime Beogradskog maratona kao Rukovaoca,
            a za potrebe ostvarivanja gore navedenih svrha obrade.
          </p>
          <p className={styles["para"]}>
            Primera radi, obrađivači koje Beogradski maraton je angažovao (ili
            može angažovati) jesu: 1) kompanije (service provajderi) u vezi sa
            održavanjem Veb stranice odnosno aplikacije koja je koristi na Veb
            stranici, 2) kompanije (service provajderi) koje pružaju usluge
            merenja vremena takmičara na trkama, 3) marketinške agencije za
            potrebe realizacije marketinga i oglašavanja.
          </p>
          <p className={styles["para"]}>
            Takođe, Vaši podaci o ličnosti mogu biti, na osnovu Vašeg pristanka,
            dostavljeni organizaciji Abbott World Marathon Majors (Sjedinjene
            Američke Države) u svrhe Vašeg selektiranja i kvalifikovanja za
            vodeće svetske maratone (Tokio, Boston, London, Berlin, Čikago,
            Njujork) u okviru master kategorija takmičara ovih prestižnih
            takmičenja (maratona).
          </p>

          <h2 className={styles["subtitle"]}>
            IZNOŠENJE PODATAKA O LIČNOSTI IZVAN TERITORIJE REPUBLIKE SRBIJE
          </h2>
          <p className={styles["para"]}>
            Vaši podaci o ličnosti se čuvaju u prostorijama Beogradskog maratona
            kao i na serverima u Republici Nemačkoj.
          </p>
          <p className={styles["para"]}>
            Takođe, Vaši podaci o ličnosti mogu biti učinjeni dostupnim
            određenim kategorijama primalaca izvan Republike Srbije, a kako je
            to pojašnjeno u okviru odeljka "Primaoci, kategorije primaoca". S
            tim u vezi, Beogradski maraton će se kontinuirano starati da se
            svaki prenos Vaših podataka o ličnosti izvan Republike Srbije vrši
            isključivo u skladu sa Zakonom i uz obezbeđivanje primerenog nivoa
            zaštite koji je jednak nivou koji garantuje Zakon, a što između
            ostalog podrazumeva iznošenje podataka u one države za koje je
            utvrđeno da obezbeđuju primereni nivo zaštite (članice Konvencije
            Saveta Evrope o zaštiti lica u odnosu na automatsku obradu ličnih
            podataka) ili obezbeđivanjem odgovarajućih mera zaštite (npr.
            primena standardnih ugovornih klauzula, odobrenje Poverenika za
            prenos podataka i slično) u skladu sa Zakonom. Ukoliko želite da se
            upoznate sa predmetnim merama, možete nas kontaktirati putem e-maila
            ili nam pošaljite dopis na adresu koji su navedeni u odeljku
            "Rukovalac".
          </p>

          <h2 className={styles["subtitle"]}>COOKIES</h2>
          <p className={styles["para"]}>
            Beogradski maraton integriše i koristi kolačiće kako bi omogućio
            određene funkcije Veb stranice i integrisao spoljne veb usluge i
            time poboljšao korisničko iskustvo. Takozvani "kolačići" su male
            tekstualne datoteke koje Vaš pretraživač može da uskladišti na Vašem
            pristupnom uređaju.
          </p>
          <p className={styles["para"]}>
            Možete da podesite pretraživač da odbije sve ili neke kolačiće, ili
            da Vas upozori kada veb lokacije postavljaju ili pristupaju
            kolačićima. Ako onemogućite ili odbijete kolačiće, imajte na umu da
            neki delovi Veb stranice mogu postati nedostupni ili neće pravilno
            funkcionisati. Za više informacija o kolačićima koje koristimo,
            pogledajte našu{" "}
            <span className={styles["bold"]}>Politiku kolačića.</span>
          </p>

          <h2 className={styles["subtitle"]}>AUTOMATSKO DONOŠENJE ODLUKA</h2>
          <p className={styles["para"]}>
            Beogradski maraton koristi Vaše podatke o ličnosti da donošenje
            sledećih automatskih odluka:
          </p>
          <p className={styles["para"]}>
            <span className={styles["underline"]}>
              {" "}
              Dodela takmičarskih brojeva
            </span>
            : Svakom prijaviocu za učešće na trci u organizaciji Beogradskog
            maratona se automatski dodeljuje takmičarski broj prilikom
            podnošenje odnosno procesuiranja njegove prijave.
          </p>
          <p className={styles["para"]}>
            <span className={styles["underline"]}>
              Određivanje startne pozicije/ grupe:
            </span>{" "}
            Startne pozicije takmičara na našim trkama zavise od očekivanog
            vremena na cilju, s tim u vezi podaci o ličnosti prijavljenih
            takmičara iz naših baza se koriste za određivanje startne pozicije /
            grupe na automatizovan način.
          </p>
          <p className={styles["para"]}>
            <span className={styles["underline"]}>
              Merenje vremena, rangiranje takmičara u ukupnom plasmanu:
            </span>{" "}
            Merenje vremena i rangiranje takmičara vrši se automatizovan način,
            shodno postignutim rezultatima na trci.
          </p>

          <h2 className={styles["subtitle"]}>VAŠA PRAVA</h2>
          <p className={styles["para"]}>
            Kao lice čiji se podaci obrađuju, imate pravo na obaveštenje o
            obradi Vaših podataka o ličnosti, pravo pristupa i uvida, pravo na
            ispravku, pravo na brisanje (pravo na zaborav), pravo na ograničenje
            obrade, pravo na prenosivost podataka, pravo prigovora na obradu
            podataka o ličnosti. Ukoliko želite da koristite neko od navedenih
            prava kontaktirajte nas putem e-maila ili nam pošaljite dopis na
            adresu koji su navedeni u odeljku "Rukovalac".
          </p>
          <p className={styles["para"]}>
            Kada se Vaši podaci o ličnosti obrađuju na osnovu Vašeg prethodnog
            datog pristanka, imate pravo da opozovete pristanak u svakom
            trenutku, s tim u vezi možete nas kontaktirati na gore navedeni
            email ili nam pošaljite dopis na adresu navedenu u odeljku
            "Rukovalac". Obrada podataka je nedozvoljena posle opoziva
            pristanka, s tim da opoziv pristanka ne utiče na zakonitost obrade
            podataka po osnovu pristanka datog pre njegovog povlačenja.
          </p>
          <p className={styles["para"]}>
            Ako smatrate da je obrada Vaših podataka vršena suprotno odredbama
            Zakona, možete podneti pritužbu Povereniku za informacije od javnog
            značaja i zaštitu podataka o ličnosti, Bulevar kralja Aleksandra 15,
            11120 Beograd (<span className={styles["bold"]}>"Poverenik"</span>.)
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivatePolicy;
