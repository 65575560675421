import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import PostLoginLayout from "../../components/Layout/PostLogin/PostLoginLayout";

import styles from "./PaymentFailed.module.css";

const PaymentFailed = () => {
  const { t } = useTranslation();
  const queryParams = queryString.parse(window.location.search);
  let date = new Date();
  
  const user = useSelector((state) => state.user);

  return (
    <PostLoginLayout>
      <div className={styles["container"]}>
        {errorIcon}
        <br />
        <h3 className={styles["form-title"]}>{t("payment-failed-title")}!</h3>
        <p className={styles["form-text"]}>{t("your-account-not-charged")}.</p>
        <div className={styles["wrap-container"]}>
          <div className={styles["upper-container"]}>
            <div className={styles["text-container"]}>
              <div className={styles["left-container"]}>
                <h3 className={styles["form-title"]}>{t("user-data")}</h3>
                <p>
                  {t("name")}: {user.name} {user.lastname}
                </p>
                <p>Email: {user.email}</p>
              </div>
              <div className={styles["right-container"]}>
                <h3 className={styles["form-title"]}>{t("order-data")}</h3>
                <p>{t("race-entry")}</p>
              </div>
            </div>
          </div>
          <div className={styles["text-container"]}>
            <div className={styles["left-container"]}>
              <h3 className={styles["form-title"]}>{t("race-entry")}</h3>
              <p>{t("name")}: Beogradski maraton d.o.o</p>
              <p>PIB:100223037</p>
              <p>
                {t("address-label")}: {t("address")}
              </p>
            </div>
            <div className={styles["right-container"]}>
              <h3 className={styles["form-title"]}>{t("transaction-title")}</h3>
              <p>
                {t("date-and-time")}:{" "}
                {date.getFullYear() +
                  "-" +
                  (date.getMonth() + 1) +
                  "-" +
                  date.getDate() +
                  " " +
                  date.getHours() +
                  ":" +
                  date.getMinutes()}
              </p>
              <p>AuthCode: {queryParams.AuthCode}</p>
              <p>Order ID: {queryParams.oid}</p>
              <p>Response: {queryParams.Response}</p>
              <p>ProcReturnCode: {queryParams.ProcReturnCode}</p>
              <p>mdStatus: {queryParams.mdStatus}</p>
            </div>
          </div>
        </div>
      </div>
    </PostLoginLayout>
  );
};

export default PaymentFailed;

const errorIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#EF4444" />
    <path
      d="M31 17L17 31"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 17L31 31"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
