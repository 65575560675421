import React from "react";
import { useSelector } from "react-redux";
import ForgotPasswordForm from "../components/ForgotPasswordForm/ForgotPasswordForm";
import RecoveryMailSent from "../components/ForgotPasswordForm/RecoveryMailSent";
import PreLoginLayout from "../components/Layout/PreLogin/PreLoginLayout";

const ForgotPassword = () => {
  const isPasswordRecovered = useSelector(
    (state) => state.success.isPasswordRecovered
  );
  return (
    <PreLoginLayout>
      {isPasswordRecovered && <RecoveryMailSent />}
      {!isPasswordRecovered && <ForgotPasswordForm />}
    </PreLoginLayout>
  );
};

export default ForgotPassword;
