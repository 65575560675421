import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { successActions } from "../../store/slices/success-slice";

import Button from "../Button/Button";
import Input from "../Input/Input";
import Anchor from "../Anchor/Anchor";
import styles from "./ForgotPasswordForm.module.css";
import { recoveryActions } from "../../store/slices/recovery-slice";
import { useTranslation } from "react-i18next";

const initialState = {
  username: "",
};

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [container, setContainer] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitErrorMsg, setSubmitErrorMsg] = useState("");

  const validEmail = new RegExp(
    "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
  );
  const emptyEmail = new RegExp("^(?!s*$).+");

  const sendRecoveryMail = (e) => {
    e.preventDefault();

    if (!emptyEmail.test(container.username)) {
      setErrorMessage(t("field") + " Email " + t("cant-be-empty") + "!");
      return;
    }

    if (!validEmail.test(container.username)) {
      setErrorMessage(t("wrong-format") + " Email " + t("of-field") + "!");
      return;
    }

    const data = {
      username: container.username,
    };
    fetch(`${process.env.REACT_APP_API_URL}/rest/jwt/passwordRecovery`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 500) {
          setSubmitErrorMsg(t("not-registered") + ".");
          throw new Error("Error 500");
        }
        if (!response.ok) {
          throw new Error("Invalid");
        }
        return response.json();
      })

      .then(() => {
        dispatch(successActions.setIsPasswordRecovered(true));
        dispatch(recoveryActions.recoverEmail(data.username));
      })
      .catch((error) => {
        console.log(error);
      });

    setErrorMessage("");

    setContainer({ username: "" });
  };

  const updateField = (e) => {
    setContainer((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <form onSubmit={sendRecoveryMail}>
      <div className={styles["page-container"]}>
        <h3 className={styles["form-title"]}>{t("forgot-password")}?</h3>
        <p className={styles["form-text"]}>{t("no-worries")}</p>
        <div className={styles["input-container"]}>
          <Input
            type="text"
            placeholder="Email"
            name="username"
            value={container.username}
            onChange={updateField}
            errorMessage={errorMessage}
          />
        </div>
        <div className={styles["button"]}>
          <Button primary stretch>
            {" "}
            {t("send-instructions")}{" "}
          </Button>
        </div>
        <div className={`${styles["input-container-error"]} ${styles.error}`}>
          {submitErrorMsg}
        </div>{" "}
        <br />
        <div className={styles["bottom-link"]}>
          <Anchor className="primary" to="/">
            {successfulIcon}
            {t("back-to-enrollment")}
          </Anchor>
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;

const successfulIcon = (
  <svg
    className={`${styles["arrow"]}`}
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.70711 0.792893C8.09763 1.18342 8.09763 1.81658 7.70711 2.20711L3.41421 6.5H15C15.5523 6.5 16 6.94771 16 7.5C16 8.05228 15.5523 8.5 15 8.5H3.41421L7.70711 12.7929C8.09763 13.1834 8.09763 13.8166 7.70711 14.2071C7.31658 14.5976 6.68342 14.5976 6.29289 14.2071L0.292893 8.20711C0.105357 8.01957 0 7.76522 0 7.5C0 7.23478 0.105357 6.98043 0.292893 6.79289L6.29289 0.792893C6.68342 0.402369 7.31658 0.402369 7.70711 0.792893Z"
      fill="#0C4076"
    ></path>
  </svg>
);
