import { getCookie, setCookie } from "../../helpers/Cookie";
import { companyActions } from "../slices/company-slice";
import { eventActions } from "../slices/event-slice";
import { paymentActions } from "../slices/payment-slice";
import { runnerActions } from "../slices/runner-slice";

export const fetchEvents = () => {
  return async (dispatch) => {
    const fetchRequest = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/rest/jwt/events/active`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error, couldn't get active events!");
      }
      const data = await response.json();
      return data.events;
    };

    try {
      const events = await fetchRequest();
      dispatch(eventActions.loadEvents(events));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchWithData = (eventId, userId) => {
  return async (dispatch) => {
    const fetchRequest = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/rest/jwt/runners?eventId=${eventId}&userId=${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error, couldn't get data for events!");
      }
      const data = await response.json();
      return data;
    };

    try {
      const eventsData = await fetchRequest();
      dispatch(eventActions.loadEventData(eventsData.runners));
      dispatch(paymentActions.loadEventData(eventsData.runners));
      if (eventsData.runners.length !== 0) {
        dispatch(runnerActions.loadRunner(eventsData.runners[0]));
        setCookie(
          "enrolledEvent",
          JSON.stringify(eventsData.runners[0]),
          24 * 60 * 60 * 1000
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchCompany = (runnerId, eventId) => {
  return async (dispatch) => {

    const fetchRequest = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/rest/jwt/companies?runnerId=${runnerId}&eventId=${eventId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error, couldn't get company data!");
      }
      const data = await response.json();
      return data;
    };

    try {
      const companyResponse = await fetchRequest()
      if (companyResponse.company !== null) {
        dispatch(companyActions.loadCompany(companyResponse.company))
      }
    } catch (error) {
      console.log(error);
    }
  };
};
