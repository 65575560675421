import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user-slice";
import eventReducer from "./slices/event-slice";
import paymentReducer from "./slices/payment-slice";
import successReducer from "./slices/success-slice";
import errorsReducer from "./slices/errors-slice";
import registerDataReducer from "./slices/register-data-slice";
import authReducer from "./slices/auth-slice";
import runnerReducer from "./slices/runner-slice";
import recoveryReducer from "./slices/recovery-slice";
import teamReducer from "./slices/team-slice";
import companyReducer from "./slices/company-slice"

const store = configureStore({
  reducer: {
    user: userReducer,
    event: eventReducer,
    payment: paymentReducer,
    success: successReducer,
    errors: errorsReducer,
    registerData: registerDataReducer,
    auth: authReducer,
    runner: runnerReducer,
    recovery: recoveryReducer,
    team: teamReducer,
    company: companyReducer,
  },
});

export default store;
