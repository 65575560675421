import Footer from "../../Footer/Footer";
import Toolbar from "../../Toolbar/Toolbar";
import styles from "./PreLoginLayout.module.css";

const PreLoginLayout = (props) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.toolbar}>
            <Toolbar />
          </div>
          <div className={styles.form}>{props.children}</div>
        </div>
        <div className={styles.right}></div>
      </div>
      <Footer />
    </>
  );
};

export default PreLoginLayout;
