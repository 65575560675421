/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/slices/user-slice";
import { authActions } from "../../store/slices/auth-slice";
import { setCookie } from "../../helpers/Cookie";
import queryString from "query-string";

import Input from "../Input/Input";
import Button from "../Button/Button";
import Anchor from "../Anchor/Anchor";
import styles from "./LoginForm.module.css";
import { useTranslation } from "react-i18next";
import { fetchEvents } from "../../store/actions/event-actions";

const initialState = {
  username: "",
  password: "",
};

const LoginForm = (props) => {
  const dispatch = useDispatch();
  const queryParams = queryString.parse(window.location.search);
  const { t } = useTranslation();

  const [container, setContainer] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const validEmail = new RegExp(
    /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/
  );

  const login = (e) => {
    e.preventDefault();

    if (!container.username) {
      setErrorMessage(t("field") + " Email " + t("cant-be-empty") + "!");
      return;
    } else {
      setErrorMessage("");
    }

    if (!validEmail.test(container.username)) {
      setErrorMessage(t("wrong-format") + " Email " + t("of-field") + "!");
      return;
    } else {
      setErrorMessage("");
    }

    setIsLoading(true);

    const data = {
      username: container.username,
      password: container.password,
    };

    fetch(`${process.env.REACT_APP_API_URL}/rest/jwt/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Invalid");
        }
        setLoginErrorMessage("");
        return response.json();
      })
      .then((data) => {
        setCookie("jwt", data.accessToken, 3 * 60 * 60 * 1000);
        let userLoggedObj = data.user;
        userLoggedObj["id"] = userLoggedObj["id"].toString();
        setCookie(
          "userLogged",
          JSON.stringify(userLoggedObj),
          3 * 60 * 60 * 1000
        );

        dispatch(userActions.loadUser(data.user));
        dispatch(authActions.login());
        dispatch(fetchEvents());
        setIsLoading(false);
      })
      .then(() => {
        window.location.reload(false);
      })
      .catch((error) => {
        setLoginErrorMessage(t("wrong-email-password-or-not-verified"));
        setErrorMessage("");
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    if (queryParams.user) {
      setContainer((prevState) => {
        return {
          ...prevState,
          ["username"]: queryParams.user,
        };
      });
    }
  }, []);

  const updateField = (e) => {
    setContainer((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <form
      onSubmit={login}
      id={props.id}
      type={props.type}
      name={props.name}
      className={styles["form"]}
    >
      <div className={styles["page-container"]}>
        <h3 className={styles["form-title"]}>{t("hello")}!</h3>
        <p className={`${styles["form-text"]} ${styles["form-text-header"]}`}>
          {t("login-using")}
        </p>
        <div className={styles["input-container"]}>
          <Input
            type="text"
            placeholder="Email"
            disabled={queryParams.user}
            name="username"
            value={container.username}
            onChange={updateField}
            errorMessage={errorMessage}
          />
        </div>
        <div className={styles["input-container"]}>
          <Input
            type="password"
            placeholder={t("password")}
            name="password"
            value={container.password}
            onChange={updateField}
            changebleForPassword="true"
            errorMessage={""}
          />
        </div>
        <div className={`${styles["text-right"]}`}>
          <p
            className={`${styles["text-right-sub"]} ${styles["form-text"]} ${styles["form-text-hover"]} ${styles["text-right"]}`}
          >
            <Anchor to="/forgot-password">{t("forgotten-password")}?</Anchor>
          </p>
        </div>

        {!isLoading && (
          <Button primary stretch className="primary">
            {" "}
            {t("login-btn")}{" "}
          </Button>
        )}
        {isLoading && (
          <div className={styles["button"]}>
            <Button primary stretch loading className="primary">
              {" "}
              {t("loading")}{" "}
            </Button>
          </div>
        )}
        <div className={`${styles["input-container-error"]} ${styles.error}`}>
          {loginErrorMessage}
        </div>
        <br />
        <p className={`{$styles["form-text"]} ${styles["text-bottom"]}`}>
          {t("login-old-account-disclaimer")}
        </p>
        <br />
        <p className={`{$styles["form-text"]} ${styles["text-bottom"]}`}>
          {t("no-account")}
          {"? "}
          <Anchor className="primary" to="/register" id="registerLink">
            {t("register")}
          </Anchor>
        </p>
      </div>
    </form>
  );
};

export default LoginForm;
