import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Toolbar from "../../components/Toolbar/Toolbar";
import Footer from "../../components/Footer/Footer";
import styles from "./EmailConfirmation.module.css";
import queryString from "query-string"

const EmailConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryParams = queryString.parse(window.location.search);

  setTimeout(function () {
      navigate("/",{replace: true});
  }, 3000);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const confirmEmailUrl = `${process.env.REACT_APP_API_URL}/rest/jwt/confirmEMail`;
    fetch(
      `${confirmEmailUrl}?user=${params.get("user")}&hash=${params.get("hash")}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error");
        }
        response.json();
      })
      .catch((error) => {
        navigate('/error', {replace: true})
      });
  }, []);

  return (
    <>
      <div className={styles["toolbar"]}>
        <Toolbar />
      </div>
      <div className={styles["container"]}>
        {successfulIcon}
        <div className="text-container">
          <h3 className={styles["form-title"]}>
            {t("successfully-confirmed-email")}!
          </h3>
          <p className={`${styles["form-text"]} ${styles["form-text-header"]}`}>
            {t("soon-redirect-to-login-page")}.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmailConfirmation;

const successfulIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="24" cy="24" r="24" fill="#10B981"></circle>
    <path
      d="M32.25 18L20.25 30L15 24.75"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
