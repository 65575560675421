import React from "react";
import { useSelector } from "react-redux";
import EventEnrollSuccessful from "../../components/EventEnrollSuccessful/EventEnrollSuccessful";
import Footer from "../../components/Footer/Footer";
import Toolbar from "../../components/Toolbar/Toolbar";
import styles from "./EventEnrollSuccess.module.css";

const EventEnrollSuccess = () => {
  const user = useSelector((state) => state.user);
  return (
    <>
      <div className={styles["toolbar"]}>
        <Toolbar firstLetter={user.name[0]} />
      </div>
      <EventEnrollSuccessful />
      <Footer />
    </>
  );
};

export default EventEnrollSuccess;
