import { createSlice } from "@reduxjs/toolkit";

const initialCompanySlice = {
    id: null,
    name: "",
    address: "",
    email: "",
    pib: "",
    registrationNumber: "",
}

const companySlice = createSlice({
    name: "company",
    initialState: initialCompanySlice,
    reducers: {
        loadCompany(state, action) {
            state.id = action.payload.id;
            state.pib = action.payload.pib
            state.name = action.payload.name;
            state.address = action.payload.address;
            state.email = action.payload.email;
            state.registrationNumber = action.payload.registrationNumber;
        },
        setCompanyId(state, action) {
            state.id = action.payload;
        },
        setCompanyName(state, action) {
            state.name = action.payload;
        },
        setCompanyAddress(state, action) {
            state.address = action.payload;
        },
        setCompanyEmail(state, action) {
            state.email = action.payload;
        },
        setCompanyPib(state, action) {
            state.pib = action.payload;
        },
        setCompanyRegistrationNumber(state, action) {
            state.registrationNumber = action.payload;
        },
    }
});

export const companyActions = companySlice.actions;
export default companySlice.reducer;