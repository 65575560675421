import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companyActions } from "../../store/slices/company-slice";
import Input from "../Input/Input";
import styles from "./CompanyForm.module.css";
import Button from "../Button/Button";
import { fetchCompany } from "../../helpers/fetchCompany";

const CompanyForm = (props) => {
    const dispatch = useDispatch();
    const company = useSelector((state) => state.company)
    const [isExistingCompany, setIsExistingCompany] = useState({ isExistingCompany: false });
    const [isPibDisabled, setIsPibDisabled] = useState({ isPibDisabled: false });
    const isSubmitting = useSelector((state) => state.auth.isSubmittingRunner);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setErrors(props.errors);
      }, [props.errors]);

    useEffect(() => {
        setIsExistingCompany(props.alreadyHasData)
        setIsPibDisabled(props.alreadyHasData)
    }, [props.alreadyHasData]);

    const handleCompanyPib = (e) => {
        setIsExistingCompany(false)
        dispatch(companyActions.setCompanyPib(e.target.value))
    };

    const handleCompanyName = (e) => {
        dispatch(companyActions.setCompanyName(e.target.value))
    };

    const handleCompanyAddress = (e) => {
        dispatch(companyActions.setCompanyAddress(e.target.value))
    };

    const handleCompanyEmail = (e) => {
        dispatch(companyActions.setCompanyEmail(e.target.value))
    }

    const handleRegistrationNumber = (e) => {
        dispatch(companyActions.setCompanyRegistrationNumber(e.target.value))
    };

    const setCompanyByPib = (e) => {
        fetchCompany(company.pib).then((data) => {
            if (data.company != null) {
                dispatch(companyActions.loadCompany(data.company))
                setIsExistingCompany(true)
            }
            else {
                setIsExistingCompany(false)
            }
        });
    }

    const companyErrMessage = () => {
        if(props.companyError) {
            if(props.companyError._messages[0].text.includes('Please check your company info')) {
                return <div className={styles.error}>{t("company-error")}</div>
            }

            return <div className={styles.error}>{props.companyError._messages[0].text}</div>
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.title}>{t("company-title")}</div>
            <div className={styles.info}>
                <Input 
                    placeholder={t("company-pib")}
                    value={company.pib}
                    onChange={handleCompanyPib}
                    onBlur={setCompanyByPib}
                    type="number"
                    disabled={isPibDisabled}
                    errorMessage={errors["companyPib"] && t("cant-be-empty")}
                />
                <Input 
                    placeholder={t("company-name")}
                    value={company.name}
                    onChange={handleCompanyName}
                    disabled={isExistingCompany}
                    errorMessage={errors["companyName"] && t("cant-be-empty")}
                />
                <Input 
                    placeholder={t("company-address")}
                    value={company.address}
                    onChange={handleCompanyAddress}
                    disabled={isExistingCompany}
                    errorMessage={errors["companyAddress"] && t("cant-be-empty")}
                />
                <Input 
                    placeholder={t("company-email")}
                    value={company.email}
                    onChange={handleCompanyEmail}
                    disabled={isExistingCompany}
                    errorMessage={errors["companyEmail"] && t("cant-be-empty")}
                />
                <Input 
                    placeholder={t("company-registration-number")}
                    value={company.registrationNumber}
                    onChange={handleRegistrationNumber}
                    disabled={isExistingCompany}
                    type="number"
                    errorMessage={errors["companyRegistrationNumber"] && t("cant-be-empty")}
                />
                {companyErrMessage()}
                <Button
                    onClick={props.onSubmit}
                    primary
                    stretch
                    loading={isSubmitting}
                    disabled={props.alreadyHasData}
                >
                    {t("enroll")}
                </Button>
            </div>
        </div>
    );
}

export default CompanyForm;
