import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  birthday: "",
  firstName: "",
  lastName: "",
  country: "",
  gender: "",
  language: "sr",
  username: "",
  city: "",
  phoneNo: "",
};

const registerDataSlice = createSlice({
  name: "registerData",
  initialState: initialState,
  reducers: {
    saveData(state, action) {
      state.birthday = action.payload.birthday;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.country = action.payload.country;
      state.gender = action.payload.gender;
      state.username = action.payload.username;
      state.city = action.payload.city;
      state.phoneNo = action.payload.phoneNo;
    },
  },
});

export const registerDataActions = registerDataSlice.actions;
export default registerDataSlice.reducer;
