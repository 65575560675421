import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../Dropdown/Dropdown";

import styles from "./Select.module.css";

// required:
// onChange={selectHandler} <- e.target.value, e.target.name, e.target.visibleValue
// values= single object {} OR array of objects-> [{},{} ... {}]
// if values is object {} :
//    values={{1: "one", 2: "two", 3: "three"}}
//    the key (1) gets returned as e.target.value, the value (one) is what is shown (e.target.visibleValue)
// if array of objects [{}...] :
//    values=[{name: "maraton", id: 1000}...{name: "blabla", id:1003}]
//    each object has to have a 'name', the whole object gets returned
//
// optional:
// preselect="user.gender"
// placeholder="Gender"
// errorMessage="No gender selected!"
// name="gender" <- u can access onChange with e.target.name
// disabled <- makes field inactive
// disabledValues = [true, false] <- disables options based on array that's given

const Select = (props) => {
  const { t, i18n } = useTranslation();

  const [selected, setSelected] = useState(null);
  const [showLabel, setShowLabel] = useState(selected ? true : false);
  const [showOptions, setShowOptions] = useState(false);

  const openMenu = () => {
    setShowLabel(true);
    setShowOptions(true);
  };

  useEffect(() => {
    if (props.preselect) {
      setSelected(props.preselect);
      setShowLabel(true);
    } else {
      setSelected("");
      setShowLabel(false);
    }
  }, [props.preselect, i18n.language]);

  const blurHandler = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowOptions(false);
      selected ? setShowLabel(true) : setShowLabel(false);
    }
  };

  const select = (e) => {
    setSelected(e.target.visibleValue);
    setShowOptions(false);
    if (e.target.value !== "") props.onChange && props.onChange(e);
  };

  const mainStyle = `${styles.main} 
    ${props.disabled ? styles.disabled : ""}`;

  const labelStyle = `${styles.label} 
    ${!selected && props.errorMessage ? styles.error : ""} 
    ${!showLabel ? styles.unvisible : ""}`;

  const menuButtonStyle = `${styles.button} 
    ${!selected && props.errorMessage ? styles["error-border"] : ""} 
    ${showOptions ? styles.unvisible : ""}`;

  const selectedTextStyle = selected
    ? `${styles.text}`
    : `${styles.placeholder}`;

  return (
    // tabIndex necessary to allow focus & blur to happen inside a div
    <div tabIndex="-1" onBlur={blurHandler} className={mainStyle}>
      <div>
        {!props.disableLabel && (
          <label className={labelStyle}>
            {props.label ? props.label : props.placeholder}
          </label>
        )}
        <div className={menuButtonStyle} onClick={openMenu}>
          <div className={selectedTextStyle} values={props.values}>
            {selected || props.placeholder}
          </div>
          <span className={styles.icon}>{arrowIcon}</span>
        </div>
        <Dropdown
          opened={showOptions}
          top={props.disableLabel ? "0px" : "20px"}
          values={props.values}
          visibleValues={props.visibleValues}
          disabledValues={props.disabledValues}
          preselect={props.preselect}
          name={props.name}
          onChange={select}
        />
      </div>
      {!props.disableError && (
        <div className={`${styles["input-container-error"]} ${styles.error}`}>
          {!selected && props.errorMessage}
        </div>
      )}
    </div>
  );
};

const arrowIcon = (
  <svg className={styles.svg}>
    <path d="M17 10L12 16L7 10H17Z"></path>
  </svg>
);

export default Select;
