import styles from "./Card.module.css";

const Card = (props) => {
  const cardStyle = props.className 
  ? `${styles.card} ${props.className}`
  : `${styles.card}`

  return (
    <div
      className={cardStyle}
      style={{ ...props.style, backgroundColor: props.backgroundColor }}
    >
      {props.children}
    </div>
  );
};

export default Card;
