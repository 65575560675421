import { useEffect, useState } from "react";

import svg from "../../helpers/icons";

import styles from "./HamburgerMenu.module.css";

const HamburgerMenu = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : '';
  }, [show]);

  return (
    <div className={styles.hamburger}>
      <div className={styles.button} onClick={() => setShow(!show)}>
        {!show ? svg.menu : svg.close}
      </div>
      {show && (
        <div
          className={styles.menu}
          onClick={(e) =>
            show && e.target.classList.contains(styles.menu) && setShow(false)
          }
        >
          <div className={styles.children}>{props.children}</div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
