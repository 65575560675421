import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { eventActions } from "../../store/slices/event-slice";
import { paymentActions } from "../../store/slices/payment-slice";
import { runnerActions } from "../../store/slices/runner-slice";
import { teamActions } from "../../store/slices/team-slice";
import { authActions } from "../../store/slices/auth-slice";
import {
  sendRunner,
  updateUser,
} from "../../store/actions/event-entry-actions";
import { sha512 } from "js-sha512";
import { Buffer } from "buffer";
import fetchCreateTeam from "../../helpers/fetchCreateTeam";
import fetchPrice from "../../helpers/fetchPrice";
import phoneRegex from "../../helpers/phoneRegex";

import EventInfo from "../../components/EventInfo/EventInfo";
import UserInfo from "../../components/UserInfo/UserInfo";
import PaymentInfo from "../../components/PaymentInfo/PaymentInfo";
import PostLoginLayout from "../../components/Layout/PostLogin/PostLoginLayout";
import Dialog from "../../components/Dialog/Dialog";
import Checkbox from "../../components/Checkbox/Checkbox";
import Button from "../../components/Button/Button";
import TeamForm from "../../components/TeamForm/TeamForm";
import CompanyForm from "../../components/CompanyInfo/CompanyForm";
import InputDatePickerSimple from "../../components/InputDatePickerSimple/InputDatePickerSimple";

import styles from "./EventEntry.module.css";

const EventEntry = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const team = useSelector((state) => state.team);
  const event = useSelector((state) => state.event);
  const payment = useSelector((state) => state.payment);
  const company = useSelector((state) => state.company);
  const [birthday, setBirthday] = useState("");
  const [triedBirthdaySubmit, setTriedBirthdaySubmit] = useState(false);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [teamError, setTeamError] = useState();
  const [companyError, setCompanyError] = useState();
  const isRaceRelay = event.selectedCategory.eventCategoryType === "RELAY";
  const isCorporate = event.selectedCategory.isCorporate;

  const stringToHash = `13IN060301|${event.selectedCategory.id}${user.id}|${event.priceRsd}0|${process.env.REACT_APP_API_URL}/rest/jwt/payment/creditCardSuccess|${process.env.REACT_APP_API_URL}/rest/jwt/payment/creditCardFail|Auth||PK855INH5EM5T7VYL9LA||||941|nmgCMaJi63PzpcISK`;
  const hash = Buffer.from(sha512(stringToHash), "hex").toString("base64");

  const initiateCreditCardPayment = () => {
    const bankPaymentForm = document.querySelector("#bankPaymentForm form");
    fetchPrice(event.selectedCategory.id)
      .then((response) => {
        dispatch(eventActions.updatePriceRsd(response.priceRsd));
        dispatch(eventActions.updatePriceEur(response.priceEur));
      })
      .then(() => {
        bankPaymentForm.submit();
      });
  };

  let consents = {
    consentAbbottWorldMarathonMajors: false,
    consentBgmarNewsletterAndPromotions: false,
    consentPersonalDataThirdParties: false,
  };

  useEffect(() => {
    dispatch(eventActions.reset());
    dispatch(paymentActions.reset());
  }, [i18n.language, dispatch]);

  const checkTeamname = () => {
    if (!isRaceRelay) {
      return false;
    }
    if (team.teamName === "") {
      return true;
    } else {
      return false;
    }
  };

  const checkCaptain = () => {
    if (!isRaceRelay) {
      return false;
    }
    if (
        team.members[0].mail !== user.email &&
        team.members[1].mail !== user.email &&
        team.members[2].mail !== user.email &&
        team.members[3].mail !== user.email
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkMail = (mail) => {
    if (!isRaceRelay) {
      return false;
    }
    if (mail === "") {
      return true;
    } else {
      return false;
    }
  };

  const checkCompanyPib = () => {
    return company.pib === "";
  };

  const checkCompanyName = () => {
    return company.name === "";
  };

  const checkCompanyAddress = () => {
    return company.address === "";
  };

  const checkCompanyEmail = () => {
    return company.email === "";
  };

  const checkCompanyRegistrationNumber = () => {
    return company.registrationNumber === "";
  };

  const submitHandler = () => {
    const requiredFieldsHaveError = {
      event: Object.keys(event.selectedEvent).length === 0,
      phone: !phoneRegex(user.phoneNum),
      category: Object.keys(event.selectedCategory).length === 0,
      predictedFinishTime: event.predictedFinishTime.length === 0,
      tShirtSize: event.tShirtSize.length === 0,
      termsOfUse: !event.termsOfUse,
      privacyPolicy: !event.privacyPolicy,
      paymentMethod:
        !isCorporate && Object.keys(payment.selectedMethod).length === 0,
      memberMail1:
        isRaceRelay && !event.alreadyHasData
          ? checkMail(team.members[0].mail)
          : false,
      memberMail2:
        isRaceRelay && !event.alreadyHasData
          ? checkMail(team.members[1].mail)
          : false,
      memberMail3:
        isRaceRelay && !event.alreadyHasData
          ? checkMail(team.members[2].mail)
          : false,
      memberMail4:
        isRaceRelay && !event.alreadyHasData
          ? checkMail(team.members[3].mail)
          : false,
      duplicateMail1:
        isRaceRelay && !event.alreadyHasData
          ? team.members[0].mail === team.members[1].mail ||
            team.members[0].mail === team.members[2].mail ||
            team.members[0].mail === team.members[3].mail
          : false,
      duplicateMail2:
        isRaceRelay && !event.alreadyHasData
          ? team.members[1].mail === team.members[0].mail ||
            team.members[1].mail === team.members[2].mail ||
            team.members[1].mail === team.members[3].mail
          : false,
      duplicateMail3:
        isRaceRelay && !event.alreadyHasData
          ? team.members[2].mail === team.members[0].mail ||
            team.members[2].mail === team.members[1].mail ||
            team.members[2].mail === team.members[3].mail
          : false,
      duplicateMail4:
        isRaceRelay && !event.alreadyHasData
          ? team.members[3].mail === team.members[0].mail ||
            team.members[3].mail === team.members[1].mail ||
            team.members[3].mail === team.members[2].mail
          : false,
      teamName: isRaceRelay && !event.alreadyHasData ? checkTeamname() : false,
      captainMail:
          isRaceRelay && !event.alreadyHasData ? checkCaptain() : false,
      companyName:
        isCorporate && !event.alreadyHasData ? checkCompanyName() : false,
      companyPib:
        isCorporate && !event.alreadyHasData ? checkCompanyPib() : false,
      companyAddress:
        isCorporate && !event.alreadyHasData ? checkCompanyAddress() : false,
      companyEmail:
        isCorporate && !event.alreadyHasData ? checkCompanyEmail() : false,
      companyRegistrationNumber:
        isCorporate && !event.alreadyHasData
          ? checkCompanyRegistrationNumber()
          : false,
    };
    setErrors(requiredFieldsHaveError);
    let error = false;
    Object.values(requiredFieldsHaveError).forEach((fieldError) => {
      if (fieldError) {
        error = true;
        return;
      }
    });
    if (error) return;

    const runnerData = {
      runner: {
        eventId: event.selectedEvent.id,
        userId: user.id,
        tShirtSize: event.tShirtSize,
        paymentId: event.selectedCategory.id + "-" + user.id,
        paymentMethod: payment.selectedMethod.value,
        predictedFinishTime: event.predictedFinishTime,
        paymentStatus: "WAITING_VALIDATION",
        eventCategoryId: event.selectedCategory.mnemonic,
      },
    };

    const userData = {
      user: {
        id: user.id,
        username: user.email,
        country: user.country,
        city: user.city,
        phoneNo: user.phoneNum,
      },
    };

    dispatch(authActions.setIsSubmittingRunner(true));
    if (isRaceRelay && !event.alreadyHasData) {
      dispatch(teamActions.setCaptain(runnerData.runner));
      dispatch(runnerActions.loadRunner(runnerData.runner));
      const teamData = {
        categoryId: event.selectedCategory.id,
        teamName: team.teamName,
        captain: runnerData.runner,
        members: team.members,
        company: isCorporate ? company : null,
      };
      // create team fetch
      fetchCreateTeam(teamData).then((err) => {
        dispatch(authActions.setIsSubmittingRunner(false));
        console.log(err);
        if (!err._status) {
          setCompanyError(err);
          err._messages ? setTeamError(err._messages[0].text) : setErrors(err);
        } else {
          dispatch(updateUser(userData));
          dispatch(runnerActions.loadRunner(err.runner));
          if (payment.selectedMethod.value === "CC") {
            initiateCreditCardPayment();
          } else {
            navigate("/event-enroll-success");
          }
        }
      });
    } else {
      dispatch(updateUser(userData));
      dispatch(sendRunner(navigate, runnerData, event.selectedCategory.id));
    }
  };

  const toggleCheckboxHandler = (checked, e) => {
    consents[e.target.name] = checked;
  };

  const sendConsent = () => {
    dispatch(
      updateUser({
        user: {
          id: user.id,
          username: user.email,
          askedForConsent: true,
          ...consents,
        },
      })
    );
  };

  const updateBirthday = () => {
    setTriedBirthdaySubmit(true);
    if (birthday !== "") {
      dispatch(
        updateUser({
          user: {
            id: user.id,
            username: user.email,
            birthday: birthday,
          },
        })
      );
    }
  };

  const bankPayment = (
    <div style={{ display: "none" }} id="bankPaymentForm">
      <form action={process.env.REACT_APP_PAYMENT_URL} method="POST">
        <input
          type="hidden"
          name="failUrl"
          value={`${process.env.REACT_APP_API_URL}/rest/jwt/payment/creditCardFail`}
        />
        <input type="hidden" name="currency" value="941" />
        <input type="hidden" name="trantype" value="Auth" />
        <input
          type="hidden"
          name="okUrl"
          value={`${process.env.REACT_APP_API_URL}/rest/jwt/payment/creditCardSuccess`}
        />
        <input type="hidden" name="amount" value={`${event.priceRsd}0`} />
        <input
          type="hidden"
          name="oid"
          value={`${event.selectedCategory.id}${user.id}`}
        />
        <input type="hidden" name="clientid" value="13IN060301" />
        <input type="hidden" name="storetype" value="3d_pay_hosting" />
        <input type="hidden" name="lang" value="en" />
        <input type="hidden" name="hashAlgorithm" value="ver2" />
        <input type="hidden" name="rnd" value="PK855INH5EM5T7VYL9LA" />
        <input type="hidden" name="encoding" value="utf-8" />
        <input type="hidden" name="hash" value={hash} />
        <input
          type="hidden"
          name="shopurl"
          value={`${process.env.REACT_APP_REACT_HOME_URL}/event-entry`}
        />
        <button type="submit" />
      </form>
    </div>
  );

  return (
    <PostLoginLayout>
      {!user.askedForConsent && (
        <Dialog
          buttons={
            <Button primary stretch onClick={sendConsent}>
              {t("send-and-close")}
            </Button>
          }
        >
          <div className={styles["checkbox-container"]}>
            <Checkbox
              name="consentAbbottWorldMarathonMajors"
              onChange={toggleCheckboxHandler}
            >
              {t("i-give-permission-for")}{" "}
              <b>{t("abbott-world-marathon-majors-data-collection")},</b>{" "}
              {t("according-to-privacy-policy")} ({t("optional")})
            </Checkbox>
            <Checkbox
              name="consentBgmarNewsletterAndPromotions"
              onChange={toggleCheckboxHandler}
            >
              {t("i-give-data-permission-for")}{" "}
              <b>{t("newsletter-and-promotions")}</b>,{" "}
              {t("according-to-privacy-policy")} ({t("optional")})
            </Checkbox>
            <Checkbox
              name="consentPersonalDataThirdParties"
              onChange={toggleCheckboxHandler}
            >
              {t("i-give-data-permission-for")}{" "}
              <b>{t("third-parties-marketing")},</b>{" "}
              {t("according-to-privacy-policy")} ({t("optional")})
            </Checkbox>
          </div>
        </Dialog>
      )}
      {user.dateOfBirth.includes("1900-01-01") && (
        <Dialog
          title={t("confirm-birthday-title")}
          text={t("confirm-birthday-description")}
          maxWidth="600px"
          buttons={
            <Button primary stretch onClick={updateBirthday}>
              {t("send-and-close")}
            </Button>
          }
        >
          <div style={{display: "flex", justifyContent: "center"}}>
            <InputDatePickerSimple
              name="birthday"
              onSelect={(date) => setBirthday(date)}
              errorMessage={
                triedBirthdaySubmit &&
                birthday === "" &&
                `${t("field")} ${t("date-of-birth")} ${t("cant-be-empty")}!`
              }
              label={t("date-of-birth") + "*"}
            />
          </div>
        </Dialog>
      )}
      <div className={styles.top}>
        <h1 className={styles.title}>{t("event-entry-title")}</h1>
        <div className={styles.description}>{t("event-entry-description")}</div>
        {isCorporate && <div className={styles['relay-info']}>{t("event-entry-relay-info")} <a style={{textDecoration: "underline", color: "#e01b1b"}} href="mailto: stafete@bgdmarathon.org">stafete@bgdmarathon.org</a></div>}
      </div>

      <div className={styles.data}>
        <div>
          <UserInfo errors={errors} />
        </div>
        <div>
          <EventInfo errors={errors} />
        </div>
        {isRaceRelay && !event.alreadyHasData && (
          <div>
            <TeamForm errorMessage={teamError} errors={errors} />
          </div>
        )}
        {isCorporate && !event.alreadyHasData ? (
          <div>
            <CompanyForm
              errors={errors}
              companyError={companyError}
              alreadyHasData={event.alreadyHasData}
              onSubmit={submitHandler}
            />
          </div>
        ) : (
          <div>
            <PaymentInfo
              preselectMethod={payment.selectedMethod.value}
              onSubmit={submitHandler}
              user={user}
              isCorporate={isCorporate}
              errors={errors}
            />
          </div>
        )}
        {bankPayment}
      </div>
    </PostLoginLayout>
  );
};

export default EventEntry;
