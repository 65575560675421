import React from "react";
import { useSelector } from "react-redux";
import PreLoginLayout from "../components/Layout/PreLogin/PreLoginLayout";
import RegisterForm from "../components/RegisterForm/RegisterForm";
import SuccessfullRegistration from "../components/RegisterForm/SuccessfulRegistration";

const Registration = () => {
  const isRegistrated = useSelector(state=>state.success.isRegistrated)


  return (
    <PreLoginLayout>
      {!isRegistrated && <RegisterForm />}
      {isRegistrated && <SuccessfullRegistration />}
    </PreLoginLayout>
  );
};

export default Registration;
