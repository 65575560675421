import { getCookie } from "./Cookie";
const fetchUserPaymentSlip = (categoryId,userId) => {
      let request = new Request(`${process.env.REACT_APP_API_URL}/rest/jwt/users/paymentSlip?eventCategoryId=${categoryId}&userId=${userId}`, {
          method: 'GET',
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          })
      });
      
 return fetch(request)
          .then((response) => response.json())
          .then((data) => {
            return data;
          })
          .catch((error) => {
            return error;
          });

}
export default fetchUserPaymentSlip;
