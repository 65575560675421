import { createSlice } from "@reduxjs/toolkit";

const initialErrorState = {
    ageLimitError: false,
};

const errorsSlice = createSlice({
    name: "success",
    initialState: initialErrorState,
    reducers: {
        setAgeLimitError(state, action) {
            state.ageLimitError = action.payload;
        },
    },
});

export const errorsActions = errorsSlice.actions;
export default errorsSlice.reducer;
