import React from "react";
import { Link } from "react-router-dom";

import styles from "./Anchor.module.css";

const Anchor = (props) => {
  return (
    (!props.to && (
      <a
        className={props.customClass || styles[props.className]}
        id={props.id}
        href={props.href}
        name={props.name}
        onClick={props.onClick}
        target={props.target}
      >
        {props.children}
      </a>
    )) || (
      <Link
        className={props.customClass || styles[props.className]}
        to={props.to}
        id={props.id}
        name={props.name}
        onClick={props.onClick}
        target={props.target}
      >
        {props.children}
      </Link>
    )
  );
};

export default Anchor;
