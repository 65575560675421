import { getCookie, setCookie } from "./Cookie";

const fetchCreateTeam = (team) => {
      let request = new Request(`${process.env.REACT_APP_API_URL}/rest/jwt/relayTeam/createTeam`, {
          method: 'POST',
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          }),
          body:JSON.stringify(team)
      });
      
 return fetch(request)
          .then((response) => response.json())
          .then((data) => {
            setCookie(
              "enrolledEvent",
              JSON.stringify(data.runner),
              24 * 60 * 60 * 1000
            );
          
            return data;
          })

}
export default fetchCreateTeam;