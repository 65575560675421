export const generateQR = async (user, moneyDin, eventName, categoryId) => {
  if (user.name === "") {
    return;
  }
  let submittingName = "Prijava za " + eventName;
  if (submittingName.length >= 35) {
    submittingName = eventName;
  }
  if (eventName.length >= 35) {
    submittingName = "Prijava za maraton";
  }

  const data = `K:PR|V:01|C:1|R:160600000160905589|N:Beogradski maraton d.o.o.|I:RSD${moneyDin},00|P:${user.name} ${user.lastname}|SF:189|S:${submittingName}|RO:00${categoryId}-${user.id}`;
  let src;

  const fetchRequest = async () => {
    const response = await fetch("https://nbs.rs/QRcode/api/qr/generate/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
    const responseData = await response.json();
    return responseData;
  };

  try {
    const data2 = await fetchRequest();
    src = "data:image/jpeg;base64," + data2.i;
    return src;
  } catch {
    console.log("error");
  }
};
